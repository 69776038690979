import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { HotToastService } from '@ngneat/hot-toast'
import { CompanyService } from 'src/app/shared/services/company/company.service'
import { crmApiConfig } from 'src/app/config/crm-api/crm-api.config'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'
import { IfoodService } from 'src/app/shared/services/ifood/ifood.service'
import { response } from 'express'
import { ICommonResponse } from 'src/app/shared/interfaces/common-response.interface'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-register-method',
	templateUrl: './register-method.component.html',
	styleUrls: ['./register-method.component.scss']
})
export class RegisterMethodComponent implements OnInit {
	public isLoading
	form: FormGroup
	public checkpoint = 1

	public modalOpened = false

	public isBotMobile = false

  public dataDeals: any;

  public vendorId: any;

  public ifoodFrnId: any;

	public initializeForm(): void {
		const sessionForm = this.registerService.getForm()

		this.form = new FormGroup({
			register_method: new FormControl('3', [Validators.required]),
			url: new FormControl('', []),
			cnpj: new FormControl('', [Validators.required])
		})

		this.form.get('url').disable()
	}

	constructor(
		private router: Router,
		private dealService: DealService,
		private crmApiService: CrmApiService,
		private registerService: RegisterService,
		private toast: HotToastService,
		private companyService: CompanyService,
		private trackerService: TrackersService,
		private cookieService: CookieService,
		private loaderService: LoaderService,
		private utmService: UtmService,
		private ifoodService: IfoodService,
		private mixPanelService: MixPanelService,
    private activatedRoute: ActivatedRoute
	) {
		this.loaderService.loaderChange.subscribe(({ isLoading }) => {
			this.isLoading = isLoading
		})
  	}

	radioSelect(value?) {
		//erase form data
		if (value == this.form.get('register_method').value) return
		this.form.get('url').reset()
		this.form.get('cnpj').reset()
		if (value == 2) {
			this.form.get('register_method').setValue('2')
			this.form.get('url').disable()
			this.form.get('cnpj').disable()
			return
		} else if (value === '1') {
			this.form.get('register_method').setValue('1')
			this.form.get('url').enable()
			this.form.get('cnpj').disable()
		} else if ('3') {
			this.form.get('register_method').setValue('3')
			this.form.get('cnpj').enable()
			this.form.get('url').disable()
		}
	}

	toggleModal() {
		this.modalOpened = !this.modalOpened
	}

	public async checkDeal(): Promise<void> {
		const { dealId } = this.dealService.getDeal()
		if (dealId) {
			try {
				this.crmApiService.getDeal(dealId).subscribe(
					(res) =>{
						this.mixPanelService.analyticsId = res.data.customFields.find((field) => field.key === environment.UUID_KEY ).value
					}

				)
				const { data: deal } = await this.crmApiService.getDeal(dealId).toPromise()
        this.dataDeals = await this.crmApiService.getDeal(dealId).toPromise()

          //console.log('++++++++++++++++++', this.dataDeals);


				const { value: moskitDealId } = deal.externalIds.find(
					(externalId) => externalId.key === 'MOSKIT'
				)

				this.dealService.setDeal({ dealId, moskitDealId })
			} catch (error) {
				this.dealService.setDeal()
				this.router.navigate(['/'])
			}
			return
		}

		this.router.navigate(['/'])
	}

	ngOnInit() {
		this.initializeForm()
		this.checkDeal()

		const application = this.router.parseUrl(this.router.url).queryParams
			.application

		this.isBotMobile = application === 'BOT_MOBILE'

		const { dealId } = this.dealService.getDeal()

		this.crmApiService
			.updateStage(dealId, crmApiConfig.stages.pql_register)
			.toPromise()
			.catch(() => {
				console.log('Erro ao atualizar estágio')
			})

      //console.log('_++__+_+_+_-=-=-=-=-=-=-=-=', { dealId });

      this.vendorId = this.activatedRoute.snapshot.queryParamMap.get('vendorId')
      this.ifoodFrnId = this.activatedRoute.snapshot.queryParamMap.get('ifood_frn_id')
      console.log(this.ifoodFrnId, this.vendorId);


	}

	public getDevice(): 'mobile' | 'desktop' {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	public async handleSubmit(): Promise<boolean> {
		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return false
		}

		this.form.disable()

		const utms = this.utmService.getUtms()

		const { register_method, url } = this.form.value

		const { formStep: actualStep } = this.registerService.getForm()
		this.registerService.updateForm({
			register_method,
			formStep: actualStep + 1
		})

		this.trackerService.track('REGISTER-METHOD-SELECTED', {
			register_method
		})

		if (register_method === '1') {
			this.trackerService.track('TRY-IMPORT-BY-LINK')
			this.mixPanelService.track('try-import-by-link')
			if (!url.includes('https://www.ifood.com.br/delivery/')) {
				this.toast.error('Ops! URL inválida, tente novamente.')
				this.form.enable()
				this.form.get('url').setErrors({ invalidURL: true })
				return
			}

			this.loaderService.show([
				'Aguarde enquanto cadastro seu cardápio - é rapidinho!',
				'Deixa eu dar uma olhada nos seus produtos',
				'Espera mais um pouquinho que estamos importando os dados',
				'Estou cadastrando seus horários de funcionamento',
				'Deixa só eu terminar de cadastrar o endereço do seu restaurante',
				'Pronto, agora vou começar a configurar seu robozinho'
			])

			const formData = this.registerService.getForm()

			const { moskitDealId } = this.dealService.getDeal()

			const saleDTO = {
        vendorId: this.vendorId,
				password: formData.password,
				name: formData.first_name,
				// cpf_cnpj: formData.document,
				email: formData.email,
				phone: formData.phone,
				dealId: moskitDealId,
				page_name: formData.company,
				computer: formData.computer,
        ifood_frn_id: this.ifoodFrnId,
				analytics_id: this.mixPanelService.analyticsId,
				device_category: this.getDevice(),
				url,
				...utms,
				admin_v2:
					utms.application == 'BOT_MOBILE' || utms.freemium == 'true'
						? false
						: true
			}
      if(saleDTO.name == undefined && saleDTO.email == undefined && saleDTO.phone == undefined){
        saleDTO.name = this.dataDeals.data.contacts[0].name;
        saleDTO.phone = this.dataDeals.data.contacts[0].phone;
        saleDTO.email = this.dataDeals.data.contacts[0].email;
      }
	//console.log('____________________SOU ESSE CARA AUI__________________', saleDTO);


			Object.keys(saleDTO).forEach((key) =>
				saleDTO[key] == null || saleDTO[key] == 'undefined'
					? delete saleDTO[key]
					: {}
			)

			this.companyService.createCompany(saleDTO).subscribe(
				(res) => {
					if (res.success == false) {
						console.error(res)

						this.loaderService.hide()
						this.form.enable()
						this.toast.error(res.message)
						return
					}

					const $crawlerObs = this.companyService
						.getCompanyImportStatus(res.data.menuId)
						.subscribe(
							(res) => {
								if (res.data.state === 'error') {
									this.loaderService.hide()
									this.form.enable()
									$crawlerObs.unsubscribe()
									this.toast.error(res.data.message)
									return
								}
								if (res.data.state === 'completed') {
									$crawlerObs.unsubscribe()

									let expiryDate = new Date()
									expiryDate.setMonth(expiryDate.getMonth() + 2)

									this.cookieService.set(
										'ADMIN_TOKEN',
										JSON.stringify(res.data.token),
										expiryDate,
										'/'
									)

									this.loaderService.hide()
									this.mixPanelService.track('ifood-link-imported')
									this.trackerService.track('IMPORT-COMPANY')
									this.router.navigate(['/thanks'], {
										queryParamsHandling: 'preserve'
									})
									return
								}
								if (this.checkpoint != res.data.checkpoint) {
									this.checkpoint = res.data.checkpoint
									this.loaderService.changeMessage(res.data.message)
								}
								// console.log(res)
							},
							({ error }) => {
								$crawlerObs.unsubscribe()

								this.loaderService.hide()
								this.form.enable()
								this.toast.error(error.message)
								return
							}
						)
				},
				(err) => {
					console.error(err)
					this.loaderService.hide()
					this.form.enable()
					this.toast.error(err.error.message)
					return
				},
				() => {
					this.checkpoint = 1
				}
			)

			return
		}

		if (register_method === '2') {
			this.trackerService.track('TRY-MANUAL-IMPORT')
			this.mixPanelService.track('try-manual-import')
			this.router.navigate(['/register'], { queryParamsHandling: 'preserve' })
		}

		if (register_method === '3') {
			this.trackerService.track('TRY-IMPORT-COMPANY-BY-CNPJ')
			this.mixPanelService.track('try-import-company-by-cnpj')
			this.loaderService.show('Buscando cardápio')
			this.ifoodService.getByCNPJ(this.form.value.cnpj).subscribe(
				(res: ICommonResponse) => {
					this.loaderService.hide()
					this.registerService.updateForm({
						document: this.form.value.cnpj
					})
					if (res.data.length > 1) {
						this.registerService.updateForm({
							ifoodEstablishments: res.data
						})
						this.router.navigate(['/select-ifood-establishment'], {
							queryParamsHandling: 'preserve'
						})
						return
					} else if (res.data.length === 1) {
						this.loaderService.show([
							'Aguarde enquanto cadastro seu cardápio - é rapidinho!',
							'Deixa eu dar uma olhada nos seus produtos',
							'Espera mais um pouquinho que estamos importando os dados',
							'Estou cadastrando seus horários de funcionamento',
							'Deixa só eu terminar de cadastrar o endereço do seu restaurante',
							'Pronto, agora vou começar a configurar seu robozinho'
						])

						const formData = this.registerService.getForm()

						const { moskitDealId } = this.dealService.getDeal()

						const saleDTO = {
              vendorId: this.vendorId,
							password: formData.password,
							name: formData.first_name,
							// cpf_cnpj: formData.document,
							email: formData.email,
							phone: formData.phone,
							dealId: moskitDealId,
              ifood_frn_id: this.ifoodFrnId,
							computer: formData.computer,
							page_name: formData.company,
							analytics_id: this.mixPanelService.analyticsId,
							device_category: this.getDevice(),
							url: res.data[0].ifoodUrl,
							...utms
						}

            if(saleDTO.name == undefined && saleDTO.email == undefined && saleDTO.phone == undefined){
              saleDTO.name = this.dataDeals.data.contacts[0].name;
              saleDTO.phone = this.dataDeals.data.contacts[0].phone;
              saleDTO.email = this.dataDeals.data.contacts[0].email;
            }

						Object.keys(saleDTO).forEach((key) =>
							saleDTO[key] == null || saleDTO[key] == 'undefined'
								? delete saleDTO[key]
								: {}
						)

						this.companyService.createCompany(saleDTO).subscribe(
							(res) => {
								if (res.success == false) {
									console.error(res)

									this.loaderService.hide()
									this.form.enable()
									this.toast.error(res.message)
									this.mixPanelService.sendErrorPayload({payload:saleDTO, response:res})
									return
								}

								const $crawlerObs = this.companyService
									.getCompanyImportStatus(res.data.menuId)
									.subscribe(
										(res) => {
											if (res.data.state === 'error') {
												this.loaderService.hide()
												this.form.enable()
												$crawlerObs.unsubscribe()
												this.toast.error(res.data.message)
												return
											}
											if (res.data.state === 'completed') {
												$crawlerObs.unsubscribe()

												let expiryDate = new Date()
												expiryDate.setMonth(expiryDate.getMonth() + 2)

												this.cookieService.set(
													'ADMIN_TOKEN',
													JSON.stringify(res.data.token),
													expiryDate,
													'/'
												)

												this.loaderService.hide()
												this.mixPanelService.track('cnpj-imported')
												this.trackerService.track('IMPORT-COMPANY')
												this.router.navigate(['/thanks'], {
													queryParamsHandling: 'preserve'
												})
												return
											}
											if (this.checkpoint != res.data.checkpoint) {
												this.checkpoint = res.data.checkpoint
												this.loaderService.changeMessage(res.data.message)
											}
											// console.log(res)
										},
										({ error }) => {
											$crawlerObs.unsubscribe()

											this.loaderService.hide()
											this.form.enable()
											this.toast.error(error.message)
											return
										}
									)
							},
							(err) => {
								console.error(err)
								this.loaderService.hide()
								this.form.enable()
								this.toast.error(err.error.message)
								return
							},
							() => {
								this.checkpoint = 1
							}
						)

						return
					}
				},
				(err) => {
					console.error(err)
					this.loaderService.hide()
					this.form.enable()
					this.toast.error(err.error.message)
					return
				}
			)

			// this.router.navigate(['/select-ifood-establishment'], {
			// 	queryParamsHandling: 'preserve'
			// })
		}
	}
}
