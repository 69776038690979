import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { EstablishmentService } from 'src/app/shared/services/establishment/establishment.service'
import { OnboardingService } from '../../services/onboarding.service'

@Component({
	selector: 'app-activate-robot',
	templateUrl: './activate-robot.component.html',
	styleUrls: ['./activate-robot.component.scss']
})
export class ActivateRobotComponent implements OnInit {
	public stepKey
	public robotActivated = false
	constructor(
		public onboardingService: OnboardingService,
		public router: Router,
		private establishmentService: EstablishmentService,
		private cookieService: CookieService
	) {
		this.stepKey = this.onboardingService.getStepByPath(this.router.url).key
	}

	ngOnInit(): void {
		this.robotActivated = !!this.cookieService.get('anotaai_robot_activated')
		this.listenWhatsAppCodeScanner()
		document.addEventListener('ping-whats-ok', function (e) {
			this.listenWhatsAppCodeScanner()
		})
	}

	public listenWhatsAppCodeScanner() {
		this.establishmentService.getEstablishmentRobotStatus().subscribe(
			(res) => {
				this.cookieService.set('anotaai_robot_activated', 'true')
				this.robotActivated = true
				var event = new CustomEvent('qrcode-whatsapp-ok')
				document.dispatchEvent(event)
			},
			(err) => console.log('error', err)
		)
	}

	public onNext(): void {
		this.onboardingService.completeStep(this.stepKey)
		const nextStepPath = this.onboardingService.getNextStepPath(this.stepKey)
		this.router.navigate([nextStepPath])
	}

	public changeScreenToWhatsApp() {
		var event = new CustomEvent('qrcode-whatsapp')
		document.dispatchEvent(event)
	}
}
