import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { CartService } from 'src/app/shared/services/cart/cart.service'
import { ICart } from 'src/app/shared/services/cart/interfaces/cart.interface'
import { ISale } from 'src/app/shared/services/sale/interfaces/sale.interface'

@Component({
	selector: 'app-order-overview',
	templateUrl: './order-overview.component.html',
	styleUrls: ['./order-overview.component.scss']
})
export class OrderOverviewComponent {
	@Input() cart: ICart
	@Input() hideChangePlanButton;

	constructor() {}
}
