import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { RegisterService } from 'src/app/shared/services/register/register.service'

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit {
	public routeData
	public canBack = true
	public isIfood = false
	public isFromPartnerLP = false

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private registerService: RegisterService
	) {
		router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				const childRoute = this.activatedRoute.root.firstChild.firstChild

				childRoute.data.subscribe((data) => {
					const dealIdOnQuery = this.router.parseUrl(this.router.url)
						.queryParams.id_deal

					this.canBack = !dealIdOnQuery
					this.routeData = data
				})
			})
	}

	ngOnInit(): void {
		// const ifood_merchant_id =
		// 	this.router.parseUrl(this.router.url).queryParams.ifood_merchant_id ||
		// 	this.registerService.getForm().ifood_merchant_id

		this.isFromPartnerLP =
			this.router.parseUrl(this.router.url).queryParams.redirect_by ==
				'lp_ifood' || this.router.parseUrl(this.router.url).queryParams.partner

		if (this.isFromPartnerLP) {
			this.isIfood = true
			return
		}
	}
}
