import { Injectable } from '@angular/core'
import { IRegisterDTO } from './dtos/register.dto'

@Injectable({
	providedIn: 'root'
})
export class RegisterService {

	public accountAlreadyUsed: boolean = false

	constructor() {}

	getForm(): IRegisterDTO {
		const getSessionSignUpForm = sessionStorage.getItem(
			'@anota-ai/initiate/register'
		)

		return JSON.parse(getSessionSignUpForm) ?? {}
	}

	updateForm(data: object) {
		const signUpForm = this.getForm()

		const result = Object.assign(signUpForm, data)

		sessionStorage.setItem(
			'@anota-ai/initiate/register',
			JSON.stringify(result)
		)
	}

	clearForm() {
		sessionStorage.removeItem('@anota-ai/initiate/register')
	}
}
