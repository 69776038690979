import { FormControl } from '@angular/forms';
import * as Payment from 'payment';

export class CreditCardValidator {
  static validateCardNumber(control: FormControl): { error } {
    if (control) {
      const isValid = Payment.fns.validateCardNumber(control.value);

      if (!isValid) {
        return {
          error: 'CREDIT_CARD_INVALID',
        };
      }
    }

    return null;
  }

  static validateCardExpiry(control: FormControl): { error } {
    if (control) {
      const controlValue = control.value.split('/');
      let isValid = false;

      if (controlValue.length > 1) {
        const month = controlValue[0].replace(/^\s+|\s+$/g, '');
        const year = controlValue[1].replace(/^\s+|\s+$/g, '');

        isValid = Payment.fns.validateCardExpiry(month, year);
      }

      if (!isValid) {
        return {
          error: 'CREDIT_EXPIRY_INVALID',
        };
      }
    }

    return null;
  }

  static validateCardCvc(control: FormControl): { error } {
    if (control) {
      const isValid = Payment.fns.validateCardCVC(control.value);

      if (!isValid) {
        return {
          error: 'CREDIT_CARD_INVALID',
        };
      }
    }

    return null;
  }
}
