<form [formGroup]="form" (submit)="handleSubmit()" novalidate>
    <header>
        <app-back-button></app-back-button>
        <h1>Importação de cardápio por CNPJ</h1>
    </header>

    <div class="select-card" style="cursor: default;">
        <div class="card-header">
            <div class="left">
                <span class="label">{{restaurants.length}} Restaurantes encontrados</span>
            </div>
            <div class="right">

            </div>
        </div>
        <p>Selecione o restaurante desejado:</p>
        <div class="card-inputs">
            <div class="radio-input" *ngFor="let r of restaurants; let i = index">
                <input type="radio" [value]="i" [formControl]="form.controls.selected">
                <img class="avatar" [src]="r.logoUrl">
                <span><b>{{r.name}}</b></span>
            </div>
        </div>

    </div>

    <footer>
        <app-button className="btn btn-primary w-full" type="submit" label="Próximo" [isLoading]="isLoading"
            [disabled]="form.invalid"></app-button>
    </footer>
    <app-secure-website-tag></app-secure-website-tag>
</form>