import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UserService } from 'src/app/shared/services/user/user.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'

const ANOTAAI_ADMIN_URL = environment.ANOTAAI_ADMIN_URL

@Component({
	selector: 'app-create-deal',
	templateUrl: './create-deal.component.html',
	styleUrls: ['./create-deal.component.scss']
})
export class CreateDealComponent implements OnInit {
	public form: FormGroup
	public isLoading
	public userAlreadyExists = false
	public hideComputerField = false
	public userEmail
	public disableButton = false
	public shareableCode
	public ipAddress = ''

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private dealService: DealService,
		private crmApiService: CrmApiService,
		private registerService: RegisterService,
		private userService: UserService,
		private toastService: HotToastService,
		private trackerService: TrackersService,
		private utmService: UtmService,
		private mixPanelService: MixPanelService,
		private http: HttpClient
	) {}

	ngOnInit(): void {
		this.shareableCode = this.route.snapshot.queryParamMap.get('shareableCode')
		if (!this.shareableCode)
			this.shareableCode = sessionStorage.getItem('@anota-ai/initiate/shcode')
		else sessionStorage.setItem('@anota-ai/initiate/shcode', this.shareableCode)
		this.crmApiService.shareableCode = this.shareableCode

		this.initializeForm()
		this.checkDeal()
		this.utmService.setUtmCookies()
		this.getIPAddress()
	}

	//Entra nesta função caso venha do anota.ai, aqui é certeza que tem o uuid
	public async checkDeal(): Promise<void> {
		const dealIdOnQuery = this.router.parseUrl(this.router.url).queryParams
			.id_deal

		if (!dealIdOnQuery) return

		try {
			await this.crmApiService.getDeal(dealIdOnQuery).subscribe(
				async (res: any) => {
					let tries = 0
					this.dealService.setDeal({ dealId: dealIdOnQuery })
					const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
					while ((!res.data.name || !res.data.contacts) && tries < 3) {
						try {
							this.crmApiService
								.getDeal(dealIdOnQuery)
								.toPromise()
								.then((response: any) => {
									res = response
								})
							await wait(1000)
							tries++
						} catch (err) {
							tries++
						}
					}
					if (!res.data.name || !res.data.contacts) return

					this.registerService.updateForm({
						first_name: res.data.name,
						phone: res.data.contacts[0].phone,
						email: res.data.contacts[0].email,
						company: res.data.companies[0].name
					})

					this.mixPanelService.analyticsId = res.data.customFields.find(
						(field) => field.key === environment.UUID_KEY
					).value

					this.router.navigate(['/credentials'], {
						queryParamsHandling: 'preserve'
					})
				},
				(err) => {
					console.log(err)
					this.dealService.setDeal()
				}
			)
		} catch (error) {
			this.dealService.setDeal()
		}
	}

	//Entra nessa função sempre, se não tiver form preenchido ele gera um uuid,
	//o uuid não fica salvo no form, caso o user venha do remarketing ele pode ou não ter o uuid.
	//Se ele tiver o uuid, ele ja ta salvo no mixpanel service pela /remarket, se não tiver
	// (venda antigas) tem que gerar um uuid mesmo ja tendo o form preenchido (antes o uuid era gerado
	// só caso não tivesse form preenchido), é possível saber se veio pelo remarket (e tem uuid) porque o analyticsId do
	// mixpanel service vai estar preenchido.
	public initializeForm(): void {
		let sessionForm = this.registerService.getForm()
		if (
			!sessionForm.first_name ||
			this.mixPanelService.analyticsId == undefined
		) {
			this.mixPanelService.getUUID()
		}

		this.form = new FormGroup({
			first_name: new FormControl(sessionForm.first_name ?? '', [
				Validators.required
			]),
			phone: new FormControl(sessionForm.phone ?? '', [Validators.required]),
			email: new FormControl(sessionForm.email ?? '', [
				Validators.required,
				Validators.email
			]),
			company: new FormControl(sessionForm.company ?? '', [
				Validators.required
			]),
			order_day: new FormControl(sessionForm.order_day ?? '', [
				Validators.required
			]),
			computer: new FormControl(sessionForm.computer ?? '', [
				Validators.required
			]),
			acceptTerms: new FormControl(false, [Validators.required])
		})

		this.form.value.phone == ''
			? null
			: this.form.controls.phone.markAsTouched()
	}

	public async handleSubmit(): Promise<boolean> {
		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return false
		}

		this.form.disable()
		this.isLoading = true

		const utms = this.utmService.getUtms()

		const dealDTO = {
			...this.form.value,
			...utms,
			anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
			analytics_id: this.mixPanelService.analyticsId,
			site_origem: 'Trial Anota AI',
			lead_ip: this.ipAddress
		}

		// removing undefined fields

		Object.keys(dealDTO).forEach((key) =>
			dealDTO[key] == null || dealDTO[key] == 'undefined'
				? delete dealDTO[key]
				: {}
		)

		try {
			const hasUserAlreadyExists = await this.userService.checkEmail(
				this.form.value.email
			)

			this.userEmail = this.form.value.email
			this.registerService.accountAlreadyUsed = !hasUserAlreadyExists
			console.log('hasUserAlreadyExists', hasUserAlreadyExists)

			if (!hasUserAlreadyExists) {
				this.form.enable()
				this.form.get('email').setErrors({
					alreadyExists: true
				})
				this.isLoading = false
				this.userAlreadyExists = true
				window.scrollTo(0, 0)
				this.trackerService.track('EMAIL-ALREADY-EXISTS', {
					value: this.form.value.email
				})

				return
			}
			await this.crmApiService.upsertDeal(dealDTO, true).subscribe(
				(res) => {
					console.log('response', res)
					this.registerService.updateForm({
						...this.form.value,
						...this.utmService.getUtms()
					})
					this.dealService.setDeal({ dealId: res.data.dealId })
					this.trackerService.track('CREATE-DEAL')
					this.mixPanelService.track('initial-data-sent')
					this.router.navigate(['/credentials'], {
						queryParamsHandling: 'preserve'
					})
				},
				(error) => {
					console.log('error', error)
					this.toastService.error(
						'Ops! Ocorreu um erro, mas não se preocupe, você receberá informações no seu e-mail e whatsapp.',
						{ duration: 10000 }
					)

					this.disableButton = true
				}
			)
		} catch (err) {
			this.toastService.error('Ops! Ocorreu um erro. Tente novamente!')
			if (dealDTO.computer === 'Não') {
				window.location.href =
					`${environment.NO_COMPUTER_LANDBOT}?name=${dealDTO.first_name}&email=${dealDTO.email}&phone=${dealDTO.phone}&order_day=${dealDTO.order_day}&company_name=${dealDTO.company}`.replace(
						/\s+/g,
						''
					)
				return
			}
		}

		this.form.enable()
		this.isLoading = false
	}

	public redirectToLogin(): void {
		this.router.navigate(['/next-step'])
	}

	getIPAddress(){
  
	  this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
  
		this.ipAddress = res.ip;
  
	  });
  
	}
}

