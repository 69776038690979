import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { delayWhen, map, retryWhen, timer } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'

const { ANOTAAI_AUTH_BACKEND_URL } = environment

@Injectable({
	providedIn: 'root'
})
export class EstablishmentService {
	constructor(private http: HttpClient) {}

	public getEstablishment() {
		const url = `${ANOTAAI_AUTH_BACKEND_URL}/auth/establishment`
		const requestOptions = {
			headers: {
				authorization: localStorage.getItem('adminToken')
			}
		}

		return this.http.get<any>(url, requestOptions)
	}

	public getEstablishmentRobotStatus() {
		const url = `${ANOTAAI_AUTH_BACKEND_URL}/auth/establishment`
		const requestOptions = {
			headers: {
				authorization: localStorage.getItem('adminToken')
			}
		}

		return this.http.get<ICommonResponse<any>>(url, requestOptions).pipe(
			map((res: any) => {
				const establishment = res
				const extension_installed =
					establishment.extension_version &&
					establishment.extension_version != ''
				if (!extension_installed) {
					console.log('Retry')
					throw 'Error'
				}
				return res
			}),
			retryWhen((errors) => {
				return errors.pipe(
					delayWhen(() => {
						return timer(3000)
					})
				)
			})
		)
	}

	public sendTestMessage() {
		const url = `${ANOTAAI_AUTH_BACKEND_URL}/auth/message/test-whatsapp`
		const requestOptions = {
			headers: {
				authorization: localStorage.getItem('adminToken')
			}
		}
		return this.http.get(url, requestOptions)
	}
}
