import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { ICommonResponse } from 'src/app/shared/interfaces/common-response.interface'
import { PlansService } from 'src/app/shared/services/plans/plans.service'
import { SaleService } from 'src/app/shared/services/sale/sale.service'

@Component({
	selector: 'app-plans',
	templateUrl: './plans.component.html',
	styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
	public hideTrialButton = false
	public plans = []
	constructor(
		private plansService: PlansService,
		private saleService: SaleService,
		private route: ActivatedRoute,
		private cookieService: CookieService,
    private router: Router,
    private toast: HotToastService
	) {}

	async ngOnInit() {
		await this.checkToken()
		await this.getPlans()
	}

	public async checkToken() {
		const snapshot: ActivatedRouteSnapshot = this.route.snapshot
		const token =
			snapshot.queryParams.token ?? this.cookieService.get('ADMIN_TOKEN')

		if (!token) {
      this.router.navigate(['/'])
      this.toast.error('Você precisar estar logado para acessar essa página')
      return
    }

		const sale: ICommonResponse = await this.saleService
			.getSaleByToken(token)
			.toPromise()

		if (!sale.success) {
			this.router.navigate(['/'])
      this.toast.error('Você precisar estar logado para acessar essa página')
			return
		}

		if (sale.data.establishment.freemium.source == 1) {
			this.hideTrialButton = true
		}

		let expiryDate = new Date()
		expiryDate.setMonth(expiryDate.getMonth() + 2)

		this.cookieService.set(
			'ADMIN_TOKEN',
			token,
			expiryDate,
			'/'
		)
	}

	public async getPlans(): Promise<void> {
		const plans = await this.plansService.getPlans().toPromise()

		this.plans = [
			plans.filter((plan) => plan.level === 1)[0],
			plans.filter((plan) => plan.level === 2)[0],
			plans.filter((plan) => plan.level === 3)[0]
		]
	}
}
