import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SignUpComponent } from './pages/sign-up/sign-up.component'
import { ThanksComponent } from './pages/thanks/thanks.component'
import { CreateDealComponent } from './pages/sign-up/forms/create-deal/create-deal.component'
import { RegisterMethodComponent } from './pages/sign-up/forms/register-method/register-method.component'
import { RegisterManualComponent } from './pages/sign-up/forms/register-manual/register-manual.component'
import { SharedModule } from './shared/shared.module'
import { NgxMaskModule } from 'ngx-mask'
import { HttpClientModule } from '@angular/common/http'
import { HotToastModule } from '@ngneat/hot-toast'
import { CredentialsComponent } from './pages/sign-up/forms/credentials/credentials.component'
import { environment } from 'src/environments/environment.prod'
import { CheckoutComponent } from './pages/checkout/checkout.component'
import { PlansFeedComponent } from './pages/checkout/forms/plans/components/plans-feed/plans-feed.component'
import { PlansFeedCardComponent } from './pages/checkout/forms/plans/components/plans-feed/plans-feed-card/plans-feed-card.component'
import {
	FormGroupDirective,
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms'
import { CommonModule } from '@angular/common'
import { PlansComponent } from './pages/checkout/forms/plans/plans.component'
import { PaymentComponent } from './pages/checkout/forms/payment/payment.component'
import { OrderOverviewComponent } from './pages/checkout/forms/payment/components/order-overview/order-overview.component'
import { TrialCtaComponent } from './pages/checkout/forms/plans/components/trial-cta/trial-cta.component'
import { CreditCardComponent } from './pages/checkout/forms/payment/forms/credit-card/credit-card.component'
import { BillComponent } from './pages/checkout/forms/payment/forms/bill/bill.component'
import { CuponFormComponent } from './pages/checkout/forms/payment/forms/cupon-form/cupon-form.component'
import { NextStepsComponent } from './pages/next-steps/next-steps.component'
import { AccordianComponent } from './pages/next-steps/components/accordian/accordian.component'
import { ClipboardModule } from 'ngx-clipboard'
import {
	popperVariation,
	TippyModule,
	tooltipVariation
} from '@ngneat/helipopper'
import { OnboardingComponent } from './pages/onboarding/onboarding.component'
import { PrinterComponent } from './pages/onboarding/components/printer/printer.component'
import { ActivateRobotComponent } from './pages/onboarding/components/activate-robot/activate-robot.component'
import { RobotTestComponent } from './pages/onboarding/components/robot-test/robot-test.component'
import { IntroComponent } from './pages/onboarding/components/intro/intro.component'
import { FinishComponent } from './pages/onboarding/components/finish/finish.component'
import { IfoodComponent } from './pages/ifood/ifood.component'
import { AccountInfoComponent } from './pages/ifood/forms/account-info/account-info.component'
import { MenuSlugComponent } from './pages/ifood/forms/menu-slug/menu-slug.component'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import { SelectIfoodEstablishmentComponent } from './pages/sign-up/forms/select-ifood-establishment/select-ifood-establishment.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {MatExpansionModule} from '@angular/material/expansion';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatIconModule} from '@angular/material/icon';
import { BackButtonComponent } from './shared/components/back-button/back-button.component';
import { RemarketComponent } from './pages/remarket/remarket.component';
@NgModule({
	declarations: [
		AppComponent,
		SignUpComponent,
		CheckoutComponent,
		ThanksComponent,
		CreateDealComponent,
		RegisterMethodComponent,
		RegisterManualComponent,
		CredentialsComponent,
		PlansFeedComponent,
		PlansFeedCardComponent,
		PlansComponent,
		PaymentComponent,
		OrderOverviewComponent,
		TrialCtaComponent,
		CreditCardComponent,
		BillComponent,
		CuponFormComponent,
		NextStepsComponent,
		AccordianComponent,
		OnboardingComponent,
		PrinterComponent,
		ActivateRobotComponent,
		RobotTestComponent,
		IntroComponent,
		FinishComponent,
		IfoodComponent,
		AccountInfoComponent,
		MenuSlugComponent,
		SelectIfoodEstablishmentComponent,
  		BackButtonComponent,
    RemarketComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		HttpClientModule,
		NgxMaskModule.forRoot({ dropSpecialCharacters: false }),
		HotToastModule.forRoot(),
		ClipboardModule,
		TippyModule.forRoot({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: tooltipVariation,
				popper: popperVariation
			}
		}),
		GoogleTagManagerModule.forRoot({
			id: environment.TAG_MANAGER_ID
		}),
		BrowserAnimationsModule,
		MatExpansionModule,
		CdkAccordionModule,
		MatIconModule
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
