<form [formGroup]="form" (submit)="handleSubmit()" novalidate>
	<header>
		<!-- <img class="logo" src="../../../../../assets/logo.svg"> -->
		<app-back-button></app-back-button>
		<div class="alert alert-info">
			<svg
				width="23"
				height="22"
				viewBox="0 0 23 22"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M11.4431 21C16.966 21 21.4431 16.5228 21.4431 11C21.4431 5.47715 16.966 1 11.4431 1C5.92027 1 1.44312 5.47715 1.44312 11C1.44312 16.5228 5.92027 21 11.4431 21Z"
					stroke="#2B2B2B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M11.4431 7V11"
					stroke="#2B2B2B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M11.4431 15H11.4531"
					stroke="#2B2B2B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<span style="text-align: start"
				>Nossa equipe comercial entrará em contato. Enquanto isso, você pode
				continuar seu cadastro para iniciar o teste grátis.</span
			>
		</div>
		<h1>Defina uma senha</h1>
	</header>

	<!-- <app-input
		type="text"
		placeholder="Insira o seu CPF ou CNPJ"
		label="CPF ou CNPJ"
		mask="CPF_CNPJ"
		[control]="form.controls.document"
	></app-input> -->

	<app-input
		type="password"
		placeholder="Insira sua senha"
		label="Senha *"
		[control]="form.controls.password"
	>
	</app-input>

	<app-input
		type="password"
		placeholder="Confirme sua senha"
		label="Confirmar senha *"
		[control]="form.controls.passwordConfirmation"
	></app-input>

	<footer>
		<app-button
			className="btn btn-primary"
			type="submit"
			label="Próximo"
			[disabled]="form.invalid"
		>
		</app-button>
	</footer>
	<app-secure-website-tag></app-secure-website-tag>
</form>
