import { Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { crmApiConfig } from 'src/app/config/crm-api/crm-api.config'
import { AnotaaiDesktopService } from 'src/app/shared/services/anotaai-desktop/anotaai-desktop.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { SendCredentialsService } from 'src/app/shared/services/send-credentials/send-credentials.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { environment } from 'src/environments/environment'

const { BOT_MOBILE_URL } = environment

const ANOTAAI_ADMIN_URL = environment.ANOTAAI_ADMIN_URL
const ANOTAAI_RESPONDE_DOWNLOAD_URL = environment.ANOTAAI_RESPONDE_DOWNLOAD_URL

declare global {
	interface Window {
		dataLayer: any[]
	}
}

@Component({
	selector: 'app-thanks',
	templateUrl: './thanks.component.html',
	styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
	public isCellPhone = false
	public isMac = false
	public isBotMobile = false
	public faqItens = [
		{
			question: 'Como funciona o robô?',
			answer:
				'Após criar sua conta, instalar e ativar o seu robô, o atendente virtual da Anota AI irá atender todos os clientes do seu WhatsApp ao mesmo tempo de forma automática, enviando seu cardápio digital e também, sendo capaz de tirar as principais dúvidas dos seus clientes (horário de funcionamento, taxa de entrega, promoções…), além realizar a atualização do status dos seus pedidos de forma automática.'
		},
		{
			question: 'Como instalar o Aplicativo?',
			answer:
				'Primeiramente você deverá acessar o aplicativo na sua Play Store acessando o link, em seguida, clique em “Instalar”. Após baixar o aplicativo, basta conceder as permissões solicitadas e fazer o login com o e-mail e senha que você cadastrou em sua conta. '
		},
		{
			question: 'Como ativar o robô?',
			answer:
				'Após criar sua conta, instalar o aplicativo e fazer o login, você irá clicar em “Ative seu robô”. Agora, basta permitir que o app Anota AI acesse suas notificações e em seguida nosso robô irá começar a responder seus clientes. Lembrando que para que seu robô funcione você deverá manter o WhatsApp fechado e seu aplicativo Anota AI funcionando.'
		},
		{
			question: 'Como acompanhar meus pedidos?',
			answer:
				'Para acompanhar seus pedidos basta clicar no ícone de painel que fica na barra inferior do seu aplicativo. Ao clicar você poderá visualizar seu painel de pedidos, que te propiciará avançar pedidos para a fase de produção ou entrega, além de ter acesso a toda a aba lateral de ferramentas do sistema.'
		},
		{
			question: 'Quais são os requisitos para utilizar o app?',
			answer:
				' No momento você precisará possuir uma loja no iFood para que possamos copiar seu cardápio e também, um aparelho Android. Para efetuar alterações no seu cardápio você deverá contatar nosso time de suporte ou acessar sua conta por um computador clicando aqui.'
		}
	]
	public safeURL: any
	public thanksB: boolean = false
	public thanksA: boolean = false

	constructor(
		private dealService: DealService,
		private crmApiService: CrmApiService,
		private trackerService: TrackersService,
		private cookieService: CookieService,
		private router: Router,
		private route: ActivatedRoute,
		private sendCredentials: SendCredentialsService,
		private anotaaiDesktopService: AnotaaiDesktopService,
		private toast: HotToastService,
		private sanitizer: DomSanitizer
	) {
		this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(
			'https://www.youtube.com/embed/HVOYFGMK7cc'
		)
	}

	ngOnInit(): void {
		// if has token in query params add to cookies
		this.fillIsCellPhone()
		this.fillIsMac()
		this.fillIsBotMobile()
		const wichThanks = this.isBotMobile
			? this.route.snapshot.queryParamMap.get('thanks') || 'a'
			: false

		const token = this.route.snapshot.queryParamMap.get('token')

		console.log('token', token)

		if (!wichThanks) {
			if (token) {
				let expiryDate = new Date()
				expiryDate.setMonth(expiryDate.getMonth() + 2)
				this.cookieService.set(
					'ADMIN_TOKEN',
					JSON.stringify(token),
					expiryDate,
					'/'
				)
				this.downloadAnotaAI()
				// verify after if token is valid
				return
			}

			this.trackerService.track('FINISH')
			this.handleGetLinkToRedirect()
			this.indentifyApplication()
			if (
				!sessionStorage.getItem('downloadStarted') &&
				!this.isCellPhone &&
				!this.isBotMobile
			) {
				this.downloadAnotaAI()
			} else if (this.isBotMobile) {
				// this.downloadBotMobile()
			}
		}

		if (wichThanks === 'a') {
			this.thanksA = true
			this.isBotMobile = true
			this.isCellPhone = false
			this.isMac = false
		} else if (wichThanks === 'b') {
			this.thanksB = true
			this.isBotMobile = true
			this.isCellPhone = false
			this.isMac = false
		}
	}

	fillIsMac() {
		this.isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
	}

	fillIsCellPhone() {
		this.isCellPhone =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)

		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
		this.sendCredentials.sendCredentials(token).subscribe(
			(res) => {
				// console.log(res)
			},
			(err) => {
				console.log(err)
			}
		)
	}

	fillIsBotMobile() {
		const application = this.router.parseUrl(this.router.url).queryParams
			.application

		this.isBotMobile = application === 'BOT_MOBILE'
	}

	handleGetLinkToRedirect() {
		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')

		if (!token) {
			this.router.navigate(['/'], { queryParamsHandling: 'preserve' })
			return
		}
	}

	downloadAnotaAI() {
		window.open(ANOTAAI_RESPONDE_DOWNLOAD_URL, '_parent')
		// this.router.navigate(['next-step'], { queryParamsHandling: 'preserve' })
		setTimeout(() => {
			sessionStorage.setItem('downloadStarted', 'true')
		}, 2000)
	}

	downloadBotMobile() {
		window.dataLayer.push({
			event: 'downloadBotMobile'
		})
		const userAgent = navigator.userAgent.toLowerCase()

		if (/anotaaibot/i.test(userAgent)) {
			const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
			window.open(`${BOT_MOBILE_URL}/login?token=${token}`, '_self')
			return
		}

		const isAndroid = userAgent.indexOf('android') > -1
		if (isAndroid) {
			window.open('https://instalar.anota.ai/aplicativo', '_blank')
			return
		}
		window.open(
			'https://play.google.com/store/apps/details?id=ai.anota.android&hl=pt_BR',
			'_blank'
		)
	}

	indentifyApplication() {
		this.anotaaiDesktopService.verifyAnotaaiDesktop().subscribe((res) => {
			this.toast.success('Aplicação instalada com sucesso!', { duration: 5000 })
			this.toast.loading('Vamos continuar para o login...', { duration: 5000 })
			setTimeout(() => {
				this.router.navigate(['/next-step'])
			}, 5000)
		})
	}

	openChat() {
		alert('chat')
	}

	sendMessage() {
		this.trackerService.track('SEND-MESSAGE-CLICK-THANKS-PAGE')
		this.toast.success('Enviamos os próximos passos para o seu email', {
			duration: 5000
		})
	}

	openAppAnotaAI() {
		this.trackerService.track('OPEN-APP-ANOTAAI-CLICK-THANKS-PAGE')
		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
		window.open(`${ANOTAAI_ADMIN_URL}/access?token=${token}`, '_blank')
	}
}
