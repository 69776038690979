import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ifood',
  templateUrl: './ifood.component.html',
  styleUrls: ['./ifood.component.scss']
})
export class IfoodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
