export const crmApiConfig = {
	stages: {
		pql_lead: "61b7a925e7850632b8e3ad12",
		pql_register: "61af717d89f84df581c8cd49",
		pql_setup: "61af718a89f84df581c8cd4a",
		mql_lead: "61770286480a51484198215b",
		mql_video_demo: "617717d8480a514841982165",
		mql_view: "617717f2480a514841982166",
		mql_payment: "61771800480a514841982167"
	}
}
