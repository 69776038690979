import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError, timer } from 'rxjs'
import { delayWhen, map, retryWhen } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'
import { IDeal } from './interfaces/deal.interface'
import { IUpsertDealResponse } from './interfaces/upsert-deal-response.interface'

@Injectable({
	providedIn: 'root'
})
export class CrmApiService {
	private baseUrl: string
	public shareableCode;

	constructor(private http: HttpClient) {
		this.baseUrl = environment.CRM_API_URL
	}

	upsertDeal(
		deal,
		isTrial = false
	): Observable<ICommonResponse<IUpsertDealResponse>> {
		let endString = ''
		this.shareableCode? endString = `&shareableCode=${this.shareableCode}` : endString = ''
		return this.http.post<ICommonResponse<IUpsertDealResponse>>(
			`${this.baseUrl}/deals?isTrial=${isTrial}${endString}`,
			deal
		)
	}

	upsertIfoodPartnerDeal(
		deal,
		isTrial = false
	): Observable<ICommonResponse<IUpsertDealResponse>> {
		return this.http.post<ICommonResponse<IUpsertDealResponse>>(
			`${this.baseUrl}/deals?isTrial=${isTrial}&isIfoodPartner=true`,
			deal
		)
	}

	getDeal(dealId: string): Observable<ICommonResponse<IDeal>> {
		let maxRetryAttempts = 7

		return this.http
			.get<ICommonResponse<IDeal>>(`${this.baseUrl}/deals/${dealId}`)
			.pipe(
				map((entity: any) => {
					if (!entity.data.externalIds) {
						console.log('Retry')
						throw 'Error'
					}

					const { value: moskitDealId } = entity.data.externalIds.find(
						(externalId) => externalId.key === 'MOSKIT'
					)

					if (!moskitDealId) {
						console.log('Retry')
						throw 'Error'
					}

					return entity
				}),
				retryWhen((errors) => {
					return errors.pipe(
						delayWhen(() => {
							maxRetryAttempts--
							if (maxRetryAttempts === 0) {
								return throwError('Erro')
							}
							return timer(3000)
						})
					)
				})
			)
	}

	getCrmDeal(dealId: string) {
		return this.http.get<ICommonResponse<IDeal>>(
			`${this.baseUrl}/deals/${dealId}`
		)
	}

	insertNote(
		dealId: string,
		message: string
	): Observable<ICommonResponse<any>> {
		return this.http.post<ICommonResponse<any>>(
			`${this.baseUrl}/deals/${dealId}/notes`,
			{ message }
		)
	}

	updateStage(
		dealId: string,
		stageId: string
	): Observable<ICommonResponse<any>> {
		return this.http.put<ICommonResponse<any>>(
			`${this.baseUrl}/deals/${dealId}/stage`,
			{ stageId }
		)
	}

	upsertCustomFieldOnDeal(
		dealId: string,
		label: string,
		value: string
	): Observable<ICommonResponse<any>> {
		return this.http.put<ICommonResponse<any>>(
			`${this.baseUrl}/deals/${dealId}/custom-fields/${label}`,
			{ value }
		)
	}

	getDealByEmail(mail: string): Observable<ICommonResponse<IDeal>> {
		return this.http.get<ICommonResponse<IDeal>>(
			`${this.baseUrl}/deals?email=${mail}`
		)
	}

}
