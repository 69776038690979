import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'

@Injectable({
	providedIn: 'root'
})
export class IfoodService {
	baseUrl: string

	constructor(private httpClient: HttpClient) {
		this.baseUrl = environment.BACKEND_URL
	}

	getByCNPJ(cnpj) {
		const encodedCNPJ = encodeURIComponent(cnpj)
		const url = `${this.baseUrl}/noauth/ifood/get-merchant-menu-by-cnpj/${encodedCNPJ}`
		console.log(url)

		return this.httpClient.get(url)
	}
}
