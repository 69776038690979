import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

const { ANOTAAI_ADMIN_URL } = environment

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private cookieService: CookieService) {}

	canActivate():
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')

		if (token) {
			//window.location.href = `${ANOTAAI_ADMIN_URL}/access?token=${token}`
		}

		//return !token
    return true
	}
}
