import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ISale } from 'src/app/shared/services/sale/interfaces/sale.interface'
import { patterns } from 'src/app/shared/validators'
import { CpfCnpjValidator } from 'src/app/shared/validators/cpf-cnpj-validator'
import { CreditCardValidator } from 'src/app/shared/validators/credit-card-validator'

@Component({
	selector: 'app-bill',
	templateUrl: './bill.component.html',
	styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {
	@Output() onSuccess: EventEmitter<any> = new EventEmitter()
	@Input() sale: ISale
	@Input() isLoading = false

	form: FormGroup

	constructor() {}

	ngOnInit(): void {
		this.initializeForm()
	}

	initializeForm(): void {
		const { cpf_cnpj } = this.sale

		this.form = new FormGroup({
			name: new FormControl('', [
				Validators.required,
				Validators.pattern(patterns.NAME)
			]),
			cpfOrCnpj: new FormControl(cpf_cnpj ?? '', [
				Validators.required,
				CpfCnpjValidator.validate
			])
		})
	}

	handleFormSubmit(): boolean {
		window.scrollTo(0, 0)

		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return false
		}

		this.onSuccess.emit(this.form.value)
	}
}
