import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output
} from '@angular/core'
import { IPlan } from 'src/app/shared/services/plans/interfaces/plan.interface'

@Component({
	selector: 'app-plans-feed-card',
	templateUrl: './plans-feed-card.component.html',
	styleUrls: ['./plans-feed-card.component.scss']
})
export class PlansFeedCardComponent implements OnInit {
	@Input() featured = false
	@Input() plan: IPlan
	@Input() period: number
  @Input() isLoading: boolean
	@Output() changePeriod = new EventEmitter()
	@Output() selectPlan = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	selectPlanHandler(plan): void {
		this.selectPlan.emit(plan.prices[this.period]._id)
	}

	changePeriodHandler(period: number): void {
		this.changePeriod.emit(period)
	}
}
