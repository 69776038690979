import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { ConfettiService } from 'src/app/shared/services/confetti/confetti.service'
import { PrinterService } from 'src/app/shared/services/printer/printer.service'
import { OnboardingService } from '../../services/onboarding.service'

@Component({
	selector: 'app-printer',
	templateUrl: './printer.component.html',
	styleUrls: ['./printer.component.scss']
})
export class PrinterComponent implements OnInit {
	public stepKey
	public form: FormGroup
	public printersList: string[] = []
	public selectedPrinter: string = ''
	public callSupport = false

	constructor(
		public onboardingService: OnboardingService,
		public router: Router,
		private printerService: PrinterService,
		private toast: HotToastService
	) {
		this.stepKey = this.onboardingService.getStepByPath(this.router.url).key
	}

	ngOnInit(): void {
		this.initializeForm()
		this.getPrintersList()
		this.callSupportListener()
		this.selectedPrinter = localStorage.getItem('anotaai_printer') || ''
	}

	public initializeForm() {
		this.form = new FormGroup({
			printer: new FormControl(
				{
					value: localStorage.getItem('anotaai_printer') || '',
					disabled: true
				},
				[]
			)
		})
	}

	public getPrintersList(): void {
		this.printerService.listAvailablePrinters().subscribe((res: any) => {
			if (!res.printers) return

			this.callSupport = false

			this.printersList = res.printers.map((printer) => {
				return { label: printer, value: printer }
			})
			this.form.get('printer').enable()
		})
	}

	public selectPrinter() {
		this.printerService.selectPrinter(this.form.get('printer').value).subscribe(
			(res: any) => {
				if (res.sucess) {
					localStorage.setItem(
						'anotaai_printer',
						this.form.get('printer').value
					)
					this.selectedPrinter = this.form.get('printer').value
					this.toast.success('Impressora selecionada com sucesso!', {
						position: 'top-right'
					})
					console.log('Printer selected', res)
				}
			},
			(e) => {
				console.log(e)
			}
		)
	}

	public testPrinter() {
		this.printerService.testPrinter().subscribe((res) => {
			console.log('Printer test', res)
			this.toast.info('Testando impressão...', { position: 'top-right' })
		})
	}

	public callSupportListener() {
		setTimeout(() => {
			if (this.printersList.length) return
			this.callSupport = true
		}, 20000)
	}

	public onNext(): void {
		this.onboardingService.completeStep(this.stepKey)
		const nextStepPath = this.onboardingService.getNextStepPath(this.stepKey)
		this.router.navigate([nextStepPath])
	}
}
