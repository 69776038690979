import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ConfettiService } from 'src/app/shared/services/confetti/confetti.service'
import { environment } from 'src/environments/environment'
import { OnboardingService } from '../../services/onboarding.service'

const { ANOTAAI_ADMIN_URL } = environment
@Component({
	selector: 'app-finish',
	templateUrl: './finish.component.html',
	styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {
	public stepKey

	constructor(
		public onboardingService: OnboardingService,
		public router: Router,
		public confettiService: ConfettiService
	) {
		this.stepKey = this.onboardingService.getStepByPath(this.router.url).key
	}

	ngOnInit(): void {
		this.confettiService.toggleConfetti()
	}

	public onNext(): void {
		this.onboardingService.completeStep(this.stepKey)
		window.location.href = ANOTAAI_ADMIN_URL
	}
}
