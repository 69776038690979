import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { crmApiConfig } from 'src/app/config/crm-api/crm-api.config'
import { ICommonResponse } from 'src/app/shared/interfaces/common-response.interface'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'
import { CpfCnpjValidator } from 'src/app/shared/validators/cpf-cnpj-validator'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-credentials',
	templateUrl: './credentials.component.html',
	styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
	form: FormGroup
	analyticsId: any

	constructor(
		private dealService: DealService,
		private crmApiService: CrmApiService,
		private router: Router,
		private registerService: RegisterService,
		private trackerService: TrackersService,
		private toast: HotToastService,
		private utmService: UtmService,
		private mixPanelService: MixPanelService,
	) {}

	ngOnInit(): void {
		this.initializeForm()
		this.checkDeal()
		this.utmService.setUtmCookies()
	}

	public async checkDeal(): Promise<void> {
		const { dealId } = this.dealService.getDeal()
		if (!dealId) {
			this.router.navigate(['/'])
			return
		}

		this.crmApiService.getDeal(dealId).subscribe((res: ICommonResponse) => {
			const { data: deal } = res
			this.mixPanelService.analyticsId = res.data.customFields.find(
				(field) => field.key === environment.UUID_KEY
			).value
			this.registerService.updateForm({
				first_name: deal.name,
				phone: deal.contacts[0].phone,
				email: deal.contacts[0].email,
				company: deal.companies[0].name
			})
		})


		try {
			await this.crmApiService.getDeal(dealId).toPromise()
		} catch (error) {
			this.dealService.setDeal()
			this.router.navigate(['/'])
		}
	}

	public initializeForm(): void {
		const sessionForm = this.registerService.getForm()

		this.form = new FormGroup({
			// document: new FormControl(sessionForm.document ?? '', [
			// 	Validators.required,
			// 	CpfCnpjValidator.validate
			// ]),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(6)
			]),
			passwordConfirmation: new FormControl('', [
				Validators.required,
				Validators.minLength(6)
			])
		})
	}

	public async handleSubmit(): Promise<void> {
		if (this.form.invalid) {
			this.form.markAllAsTouched()
		}

		if (this.form.value.password !== this.form.value.passwordConfirmation) {
			this.toast.error('As senhas não conferem')
			this.form.get('password').setErrors({
				notEquals: true
			})
			this.form.get('passwordConfirmation').setErrors({
				notEquals: true
			})

			return
		}

		this.mixPanelService.track('create-password')
		this.trackerService.track('CREATE-CREDENTIALS')
		this.registerService.updateForm(this.form.value)

		const planIdOnQuery = this.router.parseUrl(this.router.url).queryParams
			.planId

		if (planIdOnQuery) {
			this.router.navigate(['/register'], {
				queryParamsHandling: 'preserve'
			})
			return
		}

		const ifood_merchant_id =
			this.router.parseUrl(this.router.url).queryParams.ifood_merchant_id ||
			this.registerService.getForm().ifood_merchant_id

		if (ifood_merchant_id && ifood_merchant_id !== 'undefined') {
			this.router.navigate(['/register'], {
				queryParamsHandling: 'preserve'
			})
			return
		}

		this.router.navigate(['/method'], {
			queryParamsHandling: 'preserve'
		})
	}
}
