import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-trial-cta',
	templateUrl: './trial-cta.component.html',
	styleUrls: ['./trial-cta.component.scss']
})
export class TrialCtaComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
