import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'

const BACKEND_URL = environment.OLD_BACKEND_URL
const REQUEST_OPTIONS = {
	headers: {
		authorization: environment.OLD_BACKEND_KEY
	}
}

@Injectable({
	providedIn: 'root'
})
export class PlansService {
	constructor(private http: HttpClient) {}

	public getPlans(): Observable<any> {
		const url = `${BACKEND_URL}/salesauth/sales/list-grouped-plans`

		return this.http.get(url, REQUEST_OPTIONS).pipe(
			map((response: ICommonResponse<any>) => {
				return response.data.map((plan) => {
					const constructPrices = (prices) => {
						const resultPrices = {}

						prices.forEach((element) => {
							resultPrices[element.period] = {
								price: element.price,
								externalId: element.externalId,
								_id: element._id
							}
						})

						return resultPrices
					}

					const result = {
						_id: plan._id,
						name: plan.name,
						subtitle: plan.subtitle,
						isMostRecommended: plan.level === 2,
						prices: constructPrices(plan.prices),
						level: plan.level,
						membershipFee: 200,
						descriptionList: plan.description,
						benefits: plan.features.map((feature) => {
							return {
								_id: feature._id,
								name: feature.name,
								isAvailable: feature.active,
								featured: feature.featured
							}
						})
					}

					return result
				})
			})
		)
	}

	public async getPlanById(planId: string): Promise<any> {
		const url = `${BACKEND_URL}/salesauth/sales/list-grouped-plans`

		const plans = await this.http.get<any>(url, REQUEST_OPTIONS).toPromise()
		const plan = plans.data.find((plan) => {
			return plan.prices.find((price) => price._id === planId)
		})

		if (!plan) return

		return {
			...plan,
			_id: planId,
      membershipFee: 200,
			period: plan.prices.find((price) => price._id === planId).period,
			price: plan.prices.find((price) => price._id === planId).price,
			externalId: plan.prices.find((price) => price._id === planId).externalId
		}
	}
}
