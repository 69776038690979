import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service';
import { DealService } from 'src/app/shared/services/deal/deal.service';
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service';
import { RegisterService } from 'src/app/shared/services/register/register.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-remarket',
  templateUrl: './remarket.component.html',
  styleUrls: ['./remarket.component.scss']
})
export class RemarketComponent implements OnInit {
  emailform: FormGroup
  dealForm: FormGroup
  userAlreadyExists: boolean = false;
  userEmail: string
  dealId: string
  constructor(
    private registerService: RegisterService,
    private crmApiService: CrmApiService,
    private dealService: DealService,
    private userService: UserService,
    private mixPanelService: MixPanelService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.emailform = new FormGroup({
      email: new FormControl('', [
				Validators.required,
				Validators.email
			]),
  });
  }

  handleSubmit(): void {
    console.log('submit', this.emailform)
    this.populateForm()    
  }

    async populateForm(){
    
    const response = await this.userService.checkEmailRemarket(this.emailform.value.email)
    if(response.success){
      if(response.data){    
      this.dealId = response.data.deal._id 
      if(response.data.deal.customFields.find((field) => field.key === environment.UUID_KEY)){
        this.mixPanelService.analyticsId = response.data.deal.customFields.find(
          (field) => field.key === environment.UUID_KEY
        ).value
      }
      this.registerService.updateForm({
        first_name: response.data.deal.name,
        phone: response.data.deal.contacts[0].phone,
        email: response.data.deal.contacts[0].email,
        company: response.data.deal.companies[0].name
      })
      this.router.navigate([''])
      }
      else{
        this.registerService.updateForm(
          {
            email: this.emailform.value.email
          })

        this.router.navigate([''])
      }
    }
    else{
      this.userEmail = this.emailform.value.email
      this.userAlreadyExists = true

    }
  }

}
