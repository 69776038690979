import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CompanyService } from 'src/app/shared/services/company/company.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { IfoodService } from 'src/app/shared/services/ifood/ifood.service'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'

@Component({
	selector: 'app-select-ifood-establishment',
	templateUrl: './select-ifood-establishment.component.html',
	styleUrls: ['./select-ifood-establishment.component.scss']
})
export class SelectIfoodEstablishmentComponent implements OnInit {
	public form: FormGroup
	public isLoading = false
	public restaurants = []
	public checkpoint = 1
  public dataDeals: any;

  public vendorId: any;

  public ifoodFrnId: any;

	constructor(
		private router: Router,
		private dealService: DealService,
		private crmApiService: CrmApiService,
		private registerService: RegisterService,
		private toast: HotToastService,
		private companyService: CompanyService,
		private trackerService: TrackersService,
		private cookieService: CookieService,
		private loaderService: LoaderService,
		private utmService: UtmService,
		private mixPanelService: MixPanelService,
    private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {
		const { ifoodEstablishments } = this.registerService.getForm()
		if (ifoodEstablishments) {
			this.restaurants = ifoodEstablishments
		}
		this.initializeForm()
    this.vendorId = this.activatedRoute.snapshot.queryParamMap.get('vendorId')
    this.ifoodFrnId = this.activatedRoute.snapshot.queryParamMap.get('ifood_frn_id')
	}

	public initializeForm(): void {
		this.form = new FormGroup({
			selected: new FormControl('1', [Validators.required])
		})
	}

	public getDevice(): 'mobile' | 'desktop' {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	radioSelect(event?) {
		//erase form data
		this.form.get('url').reset()
		this.form.get('cnpj').reset()
		if (!event) {
			this.form.get('url').disable()
			this.form.get('cnpj').disable()
			return
		}
		if (event.target.value === '1') {
			this.form.get('url').enable()
			this.form.get('cnpj').disable()
		} else if (event.target.value === '3') {
			this.form.get('cnpj').enable()
			this.form.get('url').disable()
		}
	}

	handleSubmit() {
		const utms = this.utmService.getUtms()
		this.loaderService.show([
			'Aguarde enquanto cadastro seu cardápio - é rapidinho!',
			'Deixa eu dar uma olhada nos seus produtos',
			'Espera mais um pouquinho que estamos importando os dados',
			'Estou cadastrando seus horários de funcionamento',
			'Deixa só eu terminar de cadastrar o endereço do seu restaurante',
			'Pronto, agora vou começar a configurar seu robozinho'
		])

		const formData = this.registerService.getForm()

		const { moskitDealId } = this.dealService.getDeal()

		const saleDTO = {
      vendorId: this.vendorId,
			password: formData.password,
			name: formData.first_name,
			// cpf_cnpj: formData.document,
			email: formData.email,
			phone: formData.phone,
			dealId: moskitDealId,
      ifood_frn_id: this.ifoodFrnId,
			computer: formData.computer,
			analytics_id: this.mixPanelService.analyticsId,
			page_name: formData.company,
			device_category: this.getDevice(),
			url: this.restaurants[this.form.get('selected').value].ifoodUrl,
			...utms
		}
    if(saleDTO.name == undefined && saleDTO.email == undefined && saleDTO.phone == undefined){
      saleDTO.name = this.dataDeals.data.contacts[0].name;
      saleDTO.phone = this.dataDeals.data.contacts[0].phone;
      saleDTO.email = this.dataDeals.data.contacts[0].email;
    }

		Object.keys(saleDTO).forEach((key) =>
			saleDTO[key] == null || saleDTO[key] == 'undefined'
				? delete saleDTO[key]
				: {}
		)

		this.companyService.createCompany(saleDTO).subscribe(
			(res) => {
				if (res.success == false) {
					console.error(res)

					this.loaderService.hide()
					this.form.enable()
					this.toast.error(res.message)
					return
				}

				const $crawlerObs = this.companyService
					.getCompanyImportStatus(res.data.menuId)
					.subscribe(
						(res) => {
							if (res.data.state === 'error') {
								this.loaderService.hide()
								this.form.enable()
								$crawlerObs.unsubscribe()
								this.toast.error(res.data.message)
								return
							}
							if (res.data.state === 'completed') {
								$crawlerObs.unsubscribe()

								let expiryDate = new Date()
								expiryDate.setMonth(expiryDate.getMonth() + 2)

								this.cookieService.set(
									'ADMIN_TOKEN',
									JSON.stringify(res.data.token),
									expiryDate,
									'/'
								)

								this.loaderService.hide()
								this.trackerService.track('IMPORT-COMPANY')
								this.mixPanelService.track('import-by-cnpj')
								this.router.navigate(['/thanks'], {
									queryParamsHandling: 'preserve'
								})
								return
							}
							if (this.checkpoint != res.data.checkpoint) {
								this.checkpoint = res.data.checkpoint
								this.loaderService.changeMessage(res.data.message)
							}
							// console.log(res)
						},
						({ error }) => {
							$crawlerObs.unsubscribe()

							this.loaderService.hide()
							this.form.enable()
							this.toast.error(error.message)
							return
						}
					)
			},
			(err) => {
				console.error(err)
				this.loaderService.hide()
				this.form.enable()
				this.toast.error(err.error.message)
				return
			},
			() => {
				this.checkpoint = 1
			}
		)

		return
	}
}
