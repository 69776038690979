import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'
import { ICreateSaleDTO } from './dtos/create-sale.dto'
import { IPaySaleDTO } from './dtos/pay-sale.dto'

const { OLD_BACKEND_URL: BACKEND_URL, OLD_BACKEND_KEY: BACKEND_KEY } =
	environment

const REQUEST_OPTIONS = {
	headers: {
		authorization: BACKEND_KEY
	}
}

@Injectable({
	providedIn: 'root'
})
export class SaleService {
	constructor(private http: HttpClient) {}

	getSaleByToken(token: string): Observable<any> {
		const url = `${BACKEND_URL}/adminauth/admin/sale`
		return this.http.get(url, { headers: { authorization: token } })
	}

	updateSalePlanByToken(token, planId) {
		const url = `${BACKEND_URL}/adminauth/admin/plan`
		return this.http.put(
			url,
			{ planId },
			{
				headers: {
					authorization: token
				}
			}
		)
	}

  updateSaleCuponByToken(token:string, couponCode:string): Observable<any>{
		const url = `${BACKEND_URL}/adminauth/admin/cupon`

		return this.http.put<ICommonResponse>(
			url,
			{ coupon: couponCode },
			{
				headers: {
					authorization: token
				}
			}
		)
	}

  deleteSaleCupon(token: string): Observable<any> {
    const url = `${BACKEND_URL}/adminauth/admin/cupon`

    return this.http.delete(url, {
      headers: {
        authorization: token
      }
    })
  }

	createSale(data: ICreateSaleDTO): Observable<any> {
		const url = `${BACKEND_URL}/salesauth/sales`
		return this.http.post(url, data, REQUEST_OPTIONS)
	}

	getSaleById(saleId: string): Observable<any> {
		const url = `${BACKEND_URL}/salesauth/sales/getbyid/${saleId}`
		return this.http.get(url, REQUEST_OPTIONS)
	}

	getSaleByDealId(dealId: string): Observable<any> {
		const url = `${BACKEND_URL}/salesauth/sales/get-by-deal-id/${dealId}`
		return this.http.get(url, REQUEST_OPTIONS)
	}

	updateSaleCuponById() {
		const url = `${BACKEND_URL}/adminauth/admin/cupon`
		return this.http.put(url, REQUEST_OPTIONS)
	}

	public pay(
		method: 'CREDIT-CARD-PAYMENT' | 'BILLET-PAYMENT',
		salePayload: IPaySaleDTO
	): Observable<any> {
		enum PAYMENT_RELATIVE_PATH {
			'CREDIT-CARD-PAYMENT' = 'creditcardpayment',
			'BILLET-PAYMENT' = 'billetpayment'
		}

		const url = `${BACKEND_URL}/noauth/sales/${PAYMENT_RELATIVE_PATH[method]}/${salePayload._id}`

		return this.http.post(url, salePayload)
	}
}
