import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
  Output
} from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ISale } from 'src/app/shared/services/sale/interfaces/sale.interface'
import { patterns } from 'src/app/shared/validators'
import { CpfCnpjValidator } from 'src/app/shared/validators/cpf-cnpj-validator'
import { CreditCardValidator } from 'src/app/shared/validators/credit-card-validator'

@Component({
	selector: 'app-credit-card',
	templateUrl: './credit-card.component.html',
	styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
	@Output() onSuccess: EventEmitter<any> = new EventEmitter()
	@Input() sale: ISale
	@Input() isFromAccordion: boolean
	@Input() isLoading = false

	form: FormGroup
	parcelsOptions: {
		value: number
		label: string
	}[]

	constructor() {}

	ngOnInit(): void {
		this.initializeForm()
		this.handleRenderParcelOptions()
	}

	initializeForm(): void {
		const { cpf } = this.sale

		this.form = new FormGroup({
			name: new FormControl('', [
				Validators.required,
				Validators.pattern(patterns.NAME)
			]),
			cardNumber: new FormControl('', [
				Validators.required,
				CreditCardValidator.validateCardNumber
			]),
			parcels: new FormControl('', [
				Validators.required,
				Validators.pattern(patterns.NUMBER)
			]),
			cpfOrCnpj: new FormControl(cpf ?? '', [
				Validators.required,
				CpfCnpjValidator.validate
			]),
			validity: new FormControl('', [
				Validators.required,
				CreditCardValidator.validateCardExpiry
			]),
			cvv: new FormControl('', [
				Validators.required,
				CreditCardValidator.validateCardCvc
			])
		})
	}

	handleRenderParcelOptions(): void {
		const { max_parcel, membershipFee, value_plan } = this.sale

		const arr = [...Array(max_parcel).keys()].map((i) => {
			const index = i + 1

			return {
				value: index,
				label: `${index}x de R$${Math.round((value_plan) / index )}`
			}
		})

		this.parcelsOptions = arr
	}

	handleFormSubmit(): boolean {
		window.scrollTo(0, 0)

		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return false
		}

		this.onSuccess.emit(this.form.value)
	}
}
