<form [formGroup]="form" novalidate (submit)="handleFormSubmit()" *ngIf="sale">
  <div class="card">
    <header class="card-header">
      1 - DADOS PARA PAGAMENTO
    </header>
    <div class="card-body">
      <app-input label="Nome do titular do cartão" placeholder="Digite o nome do titular"  [control]="form.controls.name"></app-input>
      <app-input label="CPF ou CNPJ" placeholder="Digite seu CPF ou CNPJ" mask="CPF_CNPJ" [control]="form.controls.cpfOrCnpj"></app-input>
      <app-input label="Número do cartão" mask="0000 0000 0000 0000" placeholder="Digite o número do cartão" [control]="form.controls.cardNumber"></app-input>
      <app-select
			class="input-component"
			class="col-md"
			[label]="'Quantidade de parcelas'"
			[control]="form.controls.parcels"
			[options]="parcelsOptions"
		></app-select>

      <div class="row">
        <app-input label="Validade" class="col-md" placeholder="Ex: 00/00" mask="00/00" [control]="form.controls.validity"></app-input>
        <app-input label="CVV" class="col-md" placeholder="Ex: 000" mask="000" [control]="form.controls.cvv"></app-input>
      </div>
    </div>
  </div>

  <footer>
    <p>
      <i class="fa fa-lock"></i>
      Todas as transações de nosso site são seguras
    </p>
    <app-button
      className="btn btn-primary"
      type="submit"
      label="Finalizar meu pedido"
      [isLoading]="isLoading"
      [disabled]="form.invalid"
    ></app-button>
  </footer>
</form>

