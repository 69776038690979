<div class="main-wrapper">
  <app-header></app-header>

  <div class="main-page">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
</div>
