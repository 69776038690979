<div class="main-container">
	<div *ngIf="!isCellPhone; else cellPhone">
		<div class="open-step">
			<div class="info">
				<h2>
					1. Clique no botão <strong>Abrir e fazer login</strong> ao lado:<br />
				</h2>
			</div>
			<button class="btn btn-primary" (click)="loginOnAnotaAIDesktop()">
				{{token ? 'Abrir e fazer login' : 'Abrir Aplicativo'}}
			</button>
		</div>

		<br />
		<h2>
			2. Na parte de cima do seu computador, clique em
			<strong>Abrir AnotaAIResponde</strong><br />
			como na imagem abaixo.
		</h2>
		<br />
		<img src="assets/initialize-anotaai.gif" width="750" />
		<br /><br />
		<div *ngIf="token">
			<p *ngIf="!showTokenCopy; else tokenCopyDiv">
				Não está conseguindo abrir e logar-se no aplicativo pelo botão?
				<a (click)="showTokenCopyHandle()"><i>Clique aqui</i></a>
			</p>
			<ng-template #tokenCopyDiv>
				<div class="copy-token">
					<span>
						Entre copiando seu código acesso abaixo e colando no campo
						<strong>Chave de acesso</strong> na tela de login do seu aplicativo.
						<div>
							<br />
							<p tippy="Copiar código acesso" (click)="handleGetToken()">
								{{ token }}
							</p>
						</div>
					</span>
				</div>
			</ng-template>

			<br />
		</div>
		<p>
			Ainda não baixou?
			<a (click)="downloadAnotaAI()"><i>Fazer download do aplicativo</i></a>
		</p>
	</div>
	<ng-template #cellPhone>
		<div class="phone-container" style="padding: 20px">
			<h1>
				Precisamos que você entre pelo seu computador ou notebook para realizar
				a instalação completa da Anota AI
			</h1>
			<p>
				Enviamos um e-mail para você com suas chaves de acesso para continuar!
			</p>
		</div>
	</ng-template>
</div>
