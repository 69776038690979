import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class ConfettiService {
	public state = new BehaviorSubject(null)

	constructor() {}

	public toggleConfetti() {
		this.state.next(true)
	}
}
