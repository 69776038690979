<form [formGroup]="form" (submit)="handleSubmit()" novalidate>
	<header>
		<!-- <img class="logo" src="../../../../../assets/logo.svg"> -->
		<app-back-button></app-back-button>
		<h1>Informações sobre seu estabelecimento</h1>
	</header>

	<app-input class="w-full" label="CEP *" placeholder="99999-999" type="text" [control]="form.controls.postalCode"
		[mask]="'00000-000'" (keyup)="handleCEPChange()"></app-input>
	<a [hidden]="cepInputed" href="https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php"
		target="_blank"><i>Não sei meu
			CEP</i></a>
	<div [hidden]="!cepInputed">

		<app-input class="w-full" label="Logradouro *" type="text" [control]="form.controls.street"></app-input>

		<div class="row">
			<app-input class="col-sm" label="Número *" type="number" placeholder="Ex: 15B"
				[control]="form.controls.number"></app-input>
			<app-input class="col-lg" label="Complemento" type="text" placeholder="Ex: Casa 01"
				[control]="form.controls.complement"></app-input>
		</div>

		<div class="row">
			<app-input class="col-lg" label="Bairro *" type="text" [control]="form.controls.district"></app-input>
			<app-input class="col-sm" label="Estado *" type="text" [control]="form.controls.state"></app-input>
		</div>

		<app-input class="w-full" label="Cidade *" type="text" [control]="form.controls.city"></app-input>

		<app-input type="text" placeholder="Insira o seu CPF ou CNPJ" label="CPF ou CNPJ *" mask="CPF_CNPJ"
			[control]="form.controls.document"></app-input>
	</div>
	<footer>
		<app-button className="btn btn-primary w-full" type="submit"
			[label]="plan ? 'Ir para o pagamento' : 'Começar o meu teste gratis'" [isLoading]="isLoading"
			[disabled]="form.invalid"></app-button>
	</footer>
</form>