import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CompanyService } from 'src/app/shared/services/company/company.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { UserService } from 'src/app/shared/services/user/user.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'
import { CpfCnpjValidator } from 'src/app/shared/validators/cpf-cnpj-validator'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-account-info',
	templateUrl: './account-info.component.html',
	styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
	public userAlreadyExists = false
	public isLoading = false
	public journeyCases = {
		'IFOOD-PARTNERSHIP-MENU-SLUG-NOT-REQUIRED': false,
		'IFOOD-PARTNERSHIP-MENU-SLUG-REQUIRED': false,
		'IFOOD-DEFAULT-MERCHANT-ID': false
	}

	public form: FormGroup
	constructor(
		private utmService: UtmService,
		private userService: UserService,
		private toastService: HotToastService,
		private companyService: CompanyService,
		private cookieService: CookieService,
		private router: Router,
		private loaderService: LoaderService,
		private crmApiService: CrmApiService,
		private mixPanelService: MixPanelService,
	) {}

	ngOnInit(): void {
		this.utmService.setUtmCookies()
		this.initializeForm()
		this.fillJourneyCase()
	}

	public initializeForm(): void {
		this.form = new FormGroup({
			first_name: new FormControl('', [Validators.required]),
			page_name: new FormControl('', [Validators.required]),
			phone: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			password: new FormControl('', [Validators.required]),
			passwordConfirmation: new FormControl('', [Validators.required]),
			document: new FormControl('', [
				Validators.required,
				CpfCnpjValidator.validate
			])
		})
	}

	public fillJourneyCase() {
		const utms = this.utmService.getUtms()
		console.log(utms)
		if (utms.ifood_merchant_id != 'undefined' && utms.partner == 'ifood') {
			// CASE1: usuários que vêm da lp de parceria anota.ai/parceiros/ifood com o id do merchant
			this.journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-NOT-REQUIRED'] = true
			this.form.addControl(
				'order_day',
				new FormControl('', [Validators.required])
			)
		} else if (
			utms.ifood_merchant_id == 'undefined' &&
			utms.partner == 'ifood'
		) {
			// CASE2: usuários que vêm da lp de parceria anota.ai/parceiros/ifood sem o id do merchant
			this.journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-REQUIRED'] = true
			this.form.addControl(
				'order_day',
				new FormControl('', [Validators.required])
			)
		} else if (utms.ifood_merchant_id && utms.partner != 'ifood') {
			// CASE3: usuários que vêm diretamente do ifood com o id do merchant
			this.journeyCases['IFOOD-DEFAULT-MERCHANT-ID'] = true
		}
	}

	public getDevice(): 'mobile' | 'desktop' {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	public wait(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms))
	}

	public redirectToDownload(): void {
		this.router.navigate(['/next-steps'], { queryParamsHandling: 'merge' })
	}

	public async handleSubmit() {
		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return
		}

		if (this.journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-REQUIRED']) {
			const hasUserAlreadyExists = await this.userService.checkEmail(
				this.form.value.email
			)

			if (!hasUserAlreadyExists) {
				this.toastService.error('Ops! Este e-mail já foi cadastrado.')
				this.loaderService.hide()
				this.form.enable()
				this.isLoading = false
				this.userAlreadyExists = true
				window.scrollTo(0, 0)
				return
			}
			this.cookieService.set('ifood-form-data', JSON.stringify(this.form.value))
			this.router.navigate(['/ifood/menu-slug'], {
				queryParamsHandling: 'preserve'
			})
			return
		}

		this.form.disable()
		this.isLoading = true
		this.loaderService.show([
			'Aguarde enquanto cadastro seu cardápio - é rapidinho!',
			'Deixa eu dar uma olhada nos seus produtos',
			'Espera mais um pouquinho que estamos importando os dados',
			'Estamos revisando se há algum erro de ortografia',
			'Estou cadastrando seus horários de funcionamento',
			'Deixa só eu terminar de cadastrar o endereço do seu restaurante',
			'Pronto, agora vou começar a configurar seu robozinho'
		])

		try {
			const utms = this.utmService.getUtms()
			let deal_id

			if (this.journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-NOT-REQUIRED']) {
				const dealDTO = {
					...this.form.value,
					...utms,
					company: this.form.get('page_name').value,
					computer: 'Sim',
					anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
					analytics_id: this.mixPanelService.analyticsId,
					site_origem: 'Trial Anota AI - Ifood Merchant'
				}
				// removing undefined fields
				Object.keys(dealDTO).forEach((key) =>
					dealDTO[key] == null || dealDTO[key] == 'undefined'
						? delete dealDTO[key]
						: {}
				)

				const {
					data: { dealId }
				} = await this.crmApiService
					.upsertIfoodPartnerDeal(dealDTO, true)
					.toPromise()

				while (true) {
					const getDeal = await this.crmApiService
						.getCrmDeal(dealId)
						.toPromise()
					console.log(getDeal)
					if (getDeal.data.externalIds.length) {
						const externalDealId = getDeal.data.externalIds.find(
							(externalId) => externalId?.key === 'MOSKIT'
						)
						if (externalDealId?.value) {
							deal_id = externalDealId.value
							break
						}
					}
					await this.wait(3000)
				}
			}

			const hasUserAlreadyExists = await this.userService.checkEmail(
				this.form.value.email
			)
			if (!hasUserAlreadyExists) {
				this.toastService.error('Ops! Este e-mail já foi cadastrado.')
				this.loaderService.hide()
				this.form.enable()
				this.isLoading = false
				this.userAlreadyExists = true
				window.scrollTo(0, 0)
				return
			}

			const ifoodMerchantDTO = {
				name: this.form.value.first_name,
				phone: this.form.value.phone,
				email: this.form.value.email,
				password: this.form.value.password,
				page_name: this.form.value.page_name,
				cpf_cnpj: this.form.value.document,
				...utms,
				device_category: this.getDevice(),
				anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
				site_origem: 'Trial Anota AI - Ifood Merchant',
				dealId: deal_id,
				isFromPartnerLP: undefined,
				analytics_id: this.mixPanelService.analyticsId
			}

			if (this.journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-NOT-REQUIRED']) {
				const ifoodMerchantIdSplited = utms.ifood_merchant_id.split('/')
				if (ifoodMerchantIdSplited.length > 2) {
					ifoodMerchantDTO.ifood_merchant_id =
						ifoodMerchantIdSplited[ifoodMerchantIdSplited.length - 1]
				}

				ifoodMerchantDTO.isFromPartnerLP = true
			}

			// removing undefined fields
			Object.keys(ifoodMerchantDTO).forEach((key) =>
				ifoodMerchantDTO[key] == null || ifoodMerchantDTO[key] == 'undefined'
					? delete ifoodMerchantDTO[key]
					: {}
			)

			let response
			if (this.journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-NOT-REQUIRED']) {
				console.log(ifoodMerchantDTO)
				response = await this.companyService
					.createCompany(ifoodMerchantDTO)
					.toPromise()
			} else {
				response = await this.companyService
					.createIfoodCompany(
						ifoodMerchantDTO,
						ifoodMerchantDTO.ifood_merchant_id
					)
					.toPromise()
			}

			if (!response.success) {
				this.toastService.error(response.message)
				this.loaderService.hide()
				this.form.enable()
				this.isLoading = false
				return
			}

			const $crawlerObs = this.companyService
				.getCompanyImportStatus(response.data.menuId)
				.subscribe((res) => {
					// console.log(res)
					if (res.data.state === 'completed') {
						$crawlerObs.unsubscribe()

						let expiryDate = new Date()
						expiryDate.setMonth(expiryDate.getMonth() + 2)

						// console.log(res.data.token)

						this.cookieService.set(
							'ADMIN_TOKEN',
							JSON.stringify(res.data.token),
							expiryDate,
							'/'
						)

						this.loaderService.hide()
						this.isLoading = false

						this.router.navigate(['/thanks'], {
							queryParamsHandling: 'preserve'
						})
					}
					if (res.data.state === 'error') {
						$crawlerObs.unsubscribe()
						this.form.enable()
						this.isLoading = false
						this.toastService.error(res.data.message)
						this.loaderService.hide()
					}
				})
		} catch (err) {
			console.log('err', err)
			let replyMessage = 'Ops! Ocorreu um erro. Tente novamente!'
			if (err.error?.message) {
				replyMessage = err.error.message
			}
			this.isLoading = false
			this.toastService.error(replyMessage)
			this.loaderService.hide()
		}
	}
}
