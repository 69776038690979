import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CheckoutComponent } from './pages/checkout/checkout.component'
import { PaymentComponent } from './pages/checkout/forms/payment/payment.component'
import { PlansComponent } from './pages/checkout/forms/plans/plans.component'
import { NextStepsComponent } from './pages/next-steps/next-steps.component'
import { ActivateRobotComponent } from './pages/onboarding/components/activate-robot/activate-robot.component'
import { IntroComponent } from './pages/onboarding/components/intro/intro.component'
import { OnboardingComponent } from './pages/onboarding/onboarding.component'
import { PrinterComponent } from './pages/onboarding/components/printer/printer.component'
import { RobotTestComponent } from './pages/onboarding/components/robot-test/robot-test.component'
import { CreateDealComponent } from './pages/sign-up/forms/create-deal/create-deal.component'
import { CredentialsComponent } from './pages/sign-up/forms/credentials/credentials.component'
import { RegisterManualComponent } from './pages/sign-up/forms/register-manual/register-manual.component'
import { RegisterMethodComponent } from './pages/sign-up/forms/register-method/register-method.component'
import { SignUpComponent } from './pages/sign-up/sign-up.component'
import { ThanksComponent } from './pages/thanks/thanks.component'
import { AuthGuard } from './shared/guards/auth/auth.guard'
import { FinishComponent } from './pages/onboarding/components/finish/finish.component'
import { IfoodComponent } from './pages/ifood/ifood.component'
import { AccountInfoComponent } from './pages/ifood/forms/account-info/account-info.component'
import { MenuSlugComponent } from './pages/ifood/forms/menu-slug/menu-slug.component'
import { SelectIfoodEstablishmentComponent } from './pages/sign-up/forms/select-ifood-establishment/select-ifood-establishment.component'
import { RemarketComponent } from './pages/remarket/remarket.component'

const routes: Routes = [
	{
		path: '',
		component: SignUpComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				component: CreateDealComponent,
				data: {}
			},
			{
				path: 'register',
				component: RegisterManualComponent,
				data: {
					backLink: '/method'
				}
			},
			{
				path: 'method',
				component: RegisterMethodComponent,
				data: {
					backLink: '/credentials'
				}
			},
			{
				path: 'select-ifood-establishment',
				component: SelectIfoodEstablishmentComponent,
				data: {
					backLink: '/method'
				}
			},
			{
				path: 'credentials',
				component: CredentialsComponent,
				data: {
					backLink: '/'
				}
			},
			{
				path:'remarketing',
				component: RemarketComponent
			}
		]
	},
	{
		path: 'ifood',
		component: IfoodComponent,
		children: [
			{
				path: 'account-info',
				component: AccountInfoComponent
			},
			{
				path: 'menu-slug',
				component: MenuSlugComponent,
				data: {
					backLink: '/ifood/account-info'
				}
			},
			{
				path: '',
				redirectTo: 'account-info',
				pathMatch: 'full'
			}
		]
	},
	{
		path: 'checkout',
		component: CheckoutComponent,
		children: [
			{
				path: '',
				component: PlansComponent,
				data: {}
			},
			{
				path: 'payment',
				component: PaymentComponent,
				data: {}
			}
		]
	},
	{
		path: 'thanks',
		component: ThanksComponent
	},
	{
		path: 'next-step',
		component: NextStepsComponent
	},
	{
		path: 'onboarding',
		component: OnboardingComponent,
		children: [
			{
				path: '',
				component: IntroComponent
			},
			{
				path: 'printer',
				component: PrinterComponent
			},
			{
				path: 'robot-test',
				component: RobotTestComponent
			},
			{
				path: 'activate-robot',
				component: ActivateRobotComponent
			},
			{
				path: 'finish',
				component: FinishComponent
			}
		]
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
