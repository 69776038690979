<div class="onboarding-step-content">
    <h2 class="title">{{stepKey}}. Teste seu robôzinho</h2>
    <img src="assets/robot.svg">
    <p class="subtitle">
        Verifique as mensagens enviadas no seu celular.
    </p>
    <div class="buttons">
        <app-button [ngClass]="ableToAdvance ? 'new-btn btn-secondary': 'new-btn btn-accent'" label="Enviar novamente"
            [isLoading]="isLoading" (clickEvent)="sendTestMessage()"></app-button>
        <app-button *ngIf="ableToAdvance" className="new-btn btn-primary" label="Avançar" [isLoading]="isLoading"
            (clickEvent)="onNext()"></app-button>
    </div>

</div>