import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
	OnboardingService,
	OnboardingStep
} from './services/onboarding.service'

@Component({
	selector: 'app-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
	public activeStep: OnboardingStep

	constructor(
		public onboardingService: OnboardingService,
		public router: Router
	) {}

	ngOnInit(): void {
		this.onboardingService.getSteps()
		this.onboardingService.activeStep.subscribe(
			(step: OnboardingStep) => (this.activeStep = step)
		)

		// navigate to the active step
		const activeStep = this.onboardingService.steps.find(
			(item: OnboardingStep) => item.active
		)
		if (activeStep.path === this.router.url) {
			return
		}
		this.router.navigate([activeStep.path])
	}

	public changeActiveStep(step: OnboardingStep): void {
		if (step.key === this.onboardingService.steps.length + 1) {
			return
		}
		if (step.completed || step.started) {
			this.onboardingService.changeActiveStep(step.key)
			this.router.navigate([step.path])
		}
	}
}
