import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { ClipboardService } from 'ngx-clipboard'
import { CookieService } from 'ngx-cookie-service'
import { AnotaaiDesktopService } from 'src/app/shared/services/anotaai-desktop/anotaai-desktop.service'
import { environment } from 'src/environments/environment'

const ANOTAAI_RESPONDE_DOWNLOAD_URL = environment.ANOTAAI_RESPONDE_DOWNLOAD_URL
const ANOTAAI_RESPONDE_PROTOCOL = environment.ANOTAAI_RESPONDE_PROTOCOL

@Component({
	selector: 'app-next-steps',
	templateUrl: './next-steps.component.html',
	styleUrls: ['./next-steps.component.scss']
})
export class NextStepsComponent implements OnInit {
	public tokenCopied = false
	public hasToken = false
	public showTokenCopy = false
	public isCellPhone = false
	public token

	constructor(
		public cookieService: CookieService,
		private toast: HotToastService,
		private clipboardService: ClipboardService,
		private route: ActivatedRoute,
		private anotaAiDesktopService: AnotaaiDesktopService
	) {}

	ngOnInit(): void {
		this.fillIsCellPhone()
		const token = this.route.snapshot.queryParamMap.get('token')
		if (token) {
			this.token = token
			let expiryDate = new Date()
			expiryDate.setMonth(expiryDate.getMonth() + 2)
			this.cookieService.set(
				'ADMIN_TOKEN',
				JSON.stringify(token),
				expiryDate,
				'/'
			)
			this.hasToken = true
			// verify after if token is valid
			return
		}
		this.hasToken = !!this.cookieService
			.get('ADMIN_TOKEN')
			.replace(/['"]+/g, '')

		this.tryLoginWithPoolingOnAnotaAIDesktop()
	}

	showTokenCopyHandle() {
		this.showTokenCopy = true
	}

	fillIsCellPhone() {
		this.isCellPhone =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
	}

	handleGetToken() {
		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
		if (!token) {
			this.toast.error('Token não encontrado')
			return
		}

		this.clipboardService.copy(token)
		this.tokenCopied = true
		this.toast.success(`Código copiado com sucesso!`)
		setTimeout(() => (this.tokenCopied = false), 5000)
	}

	tryLoginWithPoolingOnAnotaAIDesktop() {
		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
		if (!token) {
			return
		}

		this.anotaAiDesktopService.loginOnAnotaaiDesktop(token).subscribe(
			(res) => {
				console.log(res)
			},
			(err) => {
				console.log(err)
			}
		)
	}

	loginOnAnotaAIDesktop() {
		const token = this.cookieService.get('ADMIN_TOKEN').replace(/['"]+/g, '')
		this.toast.warning('Certifique-se que seu aplicativo está instalado')
		if (!token) {
			window.open(`${ANOTAAI_RESPONDE_PROTOCOL}://`, '_parent')
			return
		}

		const url = `${ANOTAAI_RESPONDE_PROTOCOL}://login/?token=${token}`
		window.open(url, '_parent')
	}

	downloadAnotaAI() {
		window.open(ANOTAAI_RESPONDE_DOWNLOAD_URL, '_blank')
	}

	openChat() {
		alert('chat')
	}
}
