<div class="onboarding-step-content">
    <h2 class="title">{{stepKey}}. Concluído</h2>
    <img src="assets/anotaai-celebrating.svg">

    <h3 class="subtitle">
        Uhuul! Tudo certo!
    </h3>
    <p class="subtitle">
        O seu robôzinho foi instalado, configurado, testado e está pronto para receber pedidos! 🚀.</p>

    <app-button className="new-btn btn-primary" label="Começar a usar" (clickEvent)="onNext()"></app-button>
</div>