import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'

export interface UTM {
	utm_source?: string
	utm_medium?: string
	utm_campaign?: string
	utm_term?: string
	utm_content?: string
	application?: string
	partner?: string
	gclid?: string
	fbclid?: string
	redirect_by?: string
	ifood_merchant_id?: string
	indicado_aid?: string
	terceirizado?: string
	freemium?: string
	admin_v2?: string
}

@Injectable({
	providedIn: 'root'
})
export class UtmService {
	constructor(
		private cookieService: CookieService,
		private route: ActivatedRoute
	) {}

	public setUtmCookies() {
		const utms = this.route.snapshot.queryParams
		const utmCookies = this.cookieService.getAll()
		const data: UTM = {
			utm_source: utms.utm_source || utmCookies.utm_source,
			utm_medium: utms.utm_medium || utmCookies.utm_medium,
			utm_campaign: utms.utm_campaign || utmCookies.utm_campaign,
			utm_term: utms.utm_term || utmCookies.utm_term,
			utm_content: utms.utm_content || utmCookies.utm_content,
			application: utms.application || utmCookies.application,
			partner: utms.partner || utmCookies.partner,
			gclid: utms.gclid || utmCookies.gclid,
			fbclid: utms.fbclid || utmCookies.fbclid,
			redirect_by: utms.redirect_by || utmCookies.redirect_by,
			ifood_merchant_id: utms.ifood_merchant_id || utmCookies.ifood_merchant_id,
			indicado_aid: utms.indicado_aid || utmCookies.indicado_aid,
			terceirizado: utms.terceirizado || utmCookies.terceirizado,
			freemium: utms.freemium || utmCookies.freemium,
			admin_v2: utms.admin_v2 || utmCookies.admin_v2
		}
		this.cookieService.set('utm_source', data.utm_source)
		this.cookieService.set('utm_medium', data.utm_medium)
		this.cookieService.set('utm_campaign', data.utm_campaign)
		this.cookieService.set('utm_content', data.utm_content)
		this.cookieService.set('application', data.application, new Date(new Date().getTime() + 60 * 60 * 1000))
		this.cookieService.set('utm_term', data.utm_term)
		this.cookieService.set('partner', data.partner)
		this.cookieService.set('gclid', data.gclid)
		this.cookieService.set('fbclid', data.fbclid)
		this.cookieService.set('redirect_by', data.redirect_by)
		this.cookieService.set('ifood_merchant_id', data.ifood_merchant_id)
		this.cookieService.set('indicado_aid', data.indicado_aid)
		this.cookieService.set('terceirizado', data.terceirizado)
		this.cookieService.set('freemium', data.freemium)
		this.cookieService.set('admin_v2', data.admin_v2)
	}

	public getUtms(): UTM {
		const utmCookies = this.cookieService.getAll()
		const utm = {
			utm_source: utmCookies.utm_source,
			utm_medium: utmCookies.utm_medium,
			utm_campaign: utmCookies.utm_campaign,
			utm_term: utmCookies.utm_term,
			utm_content: utmCookies.utm_content,
			application: utmCookies.utm_source == 'mobile-app' ? 'BOT_MOBILE' : utmCookies.application,
			partner: utmCookies.partner,
			gclid: utmCookies.gclid,
			fbclid: utmCookies.fbclid,
			redirect_by: utmCookies.redirect_by,
			ifood_merchant_id: utmCookies.ifood_merchant_id,
			indicado_aid: utmCookies.indicado_aid,
			terceirizado: utmCookies.terceirizado,
			freemium: utmCookies.freemium,
			admin_v2: utmCookies.admin_v2
		}
		return utm
	}
}
