<div class="onboarding-step-content" *ngIf="!robotActivated; else robotIsActivated">
	<img id="arrow" src="assets/download-arrow.svg">
	<h2 class="title">{{stepKey}}. Ative seu Robô</h2>
	<p class="subtitle">
		Antes de começar a atender os seus clientes com o nosso Robô, você precisa ativá-lo.
	</p>
	<h2 class="title">Como ativo o robô?</h2>
	<!-- <div class="robot-activation-steps"> -->
	<!-- <img src="assets/whatsapp.gif"> -->
	<div>
		<p class="subtitle">1. Acesse a área do Whatsapp Web no aplicativo Anota AI
			Responde</p>
		<p class="subtitle">2. Aponte o seu celular para ler o QR Code na tela do seu computador ou notebook</p>
		<p class="subtitle">3. Aguarde poucos segundos que o sistema identifica a ativação</p>
	</div>
	<app-button className="new-btn btn-primary" label="Ir para o WhatsApp" [isLoading]="isLoading"
		(clickEvent)="changeScreenToWhatsApp()">
	</app-button>
	<!-- </div> -->
</div>
<ng-template #robotIsActivated>
	<div class="onboarding-step-content">
		<h2 class="title">{{stepKey}}. Ative seu Robô</h2>
		<p class="subtitle">
			Você já ativou o robô, agora é só começar a atender os seus clientes.
		</p>
		<div class="buttons">
			<app-button className="new-btn btn-primary w-full" label="Avançar" (clickEvent)="onNext()"></app-button>
		</div>
	</div>
</ng-template>
<div class="backdrop"></div>