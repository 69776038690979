<div class="onboarding-step-content">
	<h2 class="title">{{stepKey}}. Estamos verificando a instalação da impressora</h2>
	<app-pulse-loader
		[info]="printersList.length ? 'Impressora verificada com sucesso!' : 'Aguarde enquanto validamos a sua impressora.'"
		[completed]="printersList.length">
	</app-pulse-loader>
	<p class="subtitle" *ngIf="callSupport">Deseja pular esta etapa? <a (click)="onNext()"><span>Configure
				depois.</span></a></p>

	<div [hidden]="!printersList.length">
		<app-select class="input-component" class="col-md"
			[placeholder]="printersList.length ? 'Selecione uma opção' : 'Aguardando a verificação...'"
			[label]="'Selecione sua impressora'" [control]="form.controls.printer" [options]="printersList">
		</app-select>
		<div class="buttons">
			<app-button className="new-btn btn-secondary w-full" label="Testar"
				[disabled]="!selectedPrinter || selectedPrinter != form.controls.printer.value"
				(clickEvent)="testPrinter()"></app-button>
			<app-button className="new-btn btn-accent w-full" label="Selecionar" (clickEvent)="selectPrinter()"
				*ngIf="!selectedPrinter || selectedPrinter != form.controls.printer.value">
			</app-button>
			<app-button className="new-btn btn-primary w-full" label="Avançar"
				*ngIf="selectedPrinter && selectedPrinter == form.controls.printer.value" (clickEvent)="onNext()">
			</app-button>
		</div>
	</div>
</div>