import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { crmApiConfig } from 'src/app/config/crm-api/crm-api.config'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CepService } from 'src/app/shared/services/cep/cep.service'
import { CompanyService } from 'src/app/shared/services/company/company.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { PlansService } from 'src/app/shared/services/plans/plans.service'
import { IRegisterDTO } from 'src/app/shared/services/register/dtos/register.dto'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { SaleService } from 'src/app/shared/services/sale/sale.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'
import { CpfCnpjValidator } from 'src/app/shared/validators/cpf-cnpj-validator'
import { environment } from 'src/environments/environment'

import * as _ from 'underscore'

@Component({
	selector: 'app-register-manual',
	templateUrl: './register-manual.component.html',
	styleUrls: ['./register-manual.component.scss']
})
export class RegisterManualComponent implements OnInit {
	public form: FormGroup
	public isLoading
	public plan: any
	public cepInputed = false
  public dataDeals: any;

  public vendorId: any;

  public ifoodFrnId: any;

	constructor(
		private cepService: CepService,
		private router: Router,
		private dealService: DealService,
		private crmApiService: CrmApiService,
		private registerService: RegisterService,
		private companyService: CompanyService,
		private toast: HotToastService,
		private trackerService: TrackersService,
		private cookieService: CookieService,
		private plansService: PlansService,
		private saleService: SaleService,
		private registeService: RegisterService,
		private loaderService: LoaderService,
		private utmService: UtmService,
		private mixPanelService: MixPanelService,
    private activatedRoute: ActivatedRoute
	) {
		this.loaderService.loaderChange.subscribe(({ isLoading }) => {
			this.isLoading = isLoading
		})
	}

	ngOnInit(): void {
		this.initializeForm()
		this.checkDeal()
		this.checkPlan()

		this.handleCEPChange = _.debounce(this.handleCEPChange, 1000)
    this.vendorId = this.activatedRoute.snapshot.queryParamMap.get('vendorId')
    this.ifoodFrnId = this.activatedRoute.snapshot.queryParamMap.get('ifood_frn_id')
	}

	public initializeForm(): void {
		const sessionForm = this.registerService.getForm()

		this.form = new FormGroup({
			document: new FormControl(sessionForm.document ?? '', [
				Validators.required,
				CpfCnpjValidator.validate
			]),
			postalCode: new FormControl(sessionForm?.address?.postalCode ?? '', [
				Validators.required
			]),
			street: new FormControl(sessionForm?.address?.street ?? '', [
				Validators.required
			]),
			number: new FormControl(sessionForm?.address?.number ?? '', [
				Validators.required
			]),
			complement: new FormControl(sessionForm?.address?.complement ?? '', []),
			district: new FormControl(sessionForm?.address?.district ?? '', [
				Validators.required
			]),
			city: new FormControl(sessionForm?.address?.city ?? '', [
				Validators.required
			]),
			state: new FormControl(sessionForm?.address?.state ?? '', [
				Validators.required
			])
		})
	}

	public getDevice(): 'mobile' | 'desktop' {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	public async checkDeal(): Promise<void> {
		const { dealId } = this.dealService.getDeal()
		if (!dealId) {
			this.router.navigate(['/'])
			return
		}
		this.crmApiService.getDeal(dealId).subscribe(
			(res) =>{
				this.mixPanelService.analyticsId = res.data.customFields.find((field) => field.key === environment.UUID_KEY ).value
			}
		)

		try {
			const { data } = await this.crmApiService.getDeal(dealId).toPromise()

			const { value: moskitExternalDealId } = data.externalIds.find(
				(externalId) => externalId.key === 'MOSKIT'
			)

			this.dealService.setDeal({
				dealId: data._id,
				moskitDealId: moskitExternalDealId
			})
		} catch (error) {
			this.dealService.setDeal()
			this.router.navigate(['/'])
		}
    this.dataDeals = await this.crmApiService.getDeal(dealId).toPromise()
	}

	public async checkPlan(): Promise<void> {
		const planIdOnQuery = this.router.parseUrl(this.router.url).queryParams
			.planId

		if (!planIdOnQuery) return

		const plan = await this.plansService.getPlanById(planIdOnQuery)

		if (!plan) return

		this.plan = plan
	}

	public async handleSubmit(): Promise<boolean> {
		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return false
		}

		const sessionForm = this.registerService.getForm()

		const utms = this.utmService.getUtms()

		const formData: IRegisterDTO = {
			...sessionForm,
			address: {
				postalCode: this.form.value.postalCode,
				street: this.form.value.street,
				number: this.form.value.number,
				complement: this.form.value.complement,
				district: this.form.value.district,
				city: this.form.value.city,
				state: this.form.value.state
			},
			document: this.form.value.document
		}

		this.form.disable()
		this.loaderService.show('Registrando...')

		const { dealId, moskitDealId } = this.dealService.getDeal()

		try {
			if (this.plan) {
				const response = await this.saleService
					.createSale({
						name: formData.first_name,
						email: formData.email,
						phone: formData.phone,
						plan_id: this.plan._id,
						dealId: moskitDealId,
						cpf: formData.document,
						cpf_cnpj: formData.document,
						establishmentName: formData.company,
						plan_name: this.plan.name,
						value_plan: this.plan.price,
						period: this.plan.period,
						external_plan_id: this.plan.externalId,
						membershipFee: this.plan.membershipFee,
						max_parcel: this.plan.period,
						cupon: ``,
						is_self_service: true,
						mainSource: 2,
						device_category: this.getDevice(),
						address: {
							city: formData.address.city,
							complement: formData.address.complement,
							district: formData.address.district,
							number: formData.address.number,
							postalCode: formData.address.postalCode,
							state: formData.address.state,
							street: formData.address.street
						}
					})
					.toPromise()

				// console.log(response)

				if (!response.success) {
					this.toast.error(response.message)
					this.loaderService.hide()
					return
				}

				this.registerService.updateForm({
					saleId: response.info.saleId,
					planId: this.plan._id
				})

				const queryParams: Params = { saleId: response.info.saleId }

				this.router.navigate([`/checkout/payment`], {
					queryParams: queryParams
				})

				let expiryDate = new Date()
				expiryDate.setMonth(expiryDate.getMonth() + 2)

				this.cookieService.set(
					'ADMIN_TOKEN',
					response.info.token,
					expiryDate,
					'/'
				)

				this.loaderService.hide()

				return
			}

			const ifood_merchant_id =
				this.router.parseUrl(this.router.url).queryParams.ifood_merchant_id ||
				sessionForm.ifood_merchant_id

			const saleDTO = {
        vendorId: this.vendorId,
				name: formData.first_name,
				cpf_cnpj: formData.document,
				email: formData.email,
				password: formData.password,
				page_name: formData.company,
				phone: formData.phone,
				dealId: moskitDealId,
        ifood_frn_id: this.ifoodFrnId,
				device_category: this.getDevice(),
				computer: formData.computer,
				analytics_id: this.mixPanelService.analyticsId,
				address: {
					name: formData.address.street,
					num: formData.address.number,
					complement: formData.address.complement,
					neighborhood: formData.address.district,
					city: formData.address.city,
					state: formData.address.state,
					postal_code: formData.address.postalCode
				},
				...utms,
				admin_v2:
					utms.application == 'BOT_MOBILE' || utms.freemium == 'true'
						? false
						: true
			}

      if(saleDTO.name == undefined && saleDTO.email == undefined && saleDTO.phone == undefined){
        saleDTO.name = this.dataDeals.data.contacts[0].name;
        saleDTO.phone = this.dataDeals.data.contacts[0].phone;
        saleDTO.email = this.dataDeals.data.contacts[0].email;
      }

			if (ifood_merchant_id && ifood_merchant_id != 'undefined') {
				saleDTO['ifood_merchant_id'] = ifood_merchant_id
			}

			Object.keys(saleDTO).forEach((key) =>
				saleDTO[key] == null || saleDTO[key] == 'undefined'
					? delete saleDTO[key]
					: {}
			)

			const response = await this.companyService
				.createCompany(saleDTO)
				.toPromise()

			if (!response.success) {
				this.toast.error(response.message)
				this.loaderService.hide()
				this.mixPanelService.sendErrorPayload({payload:saleDTO, response:response})
				return
			}

			const $crawlerObs = this.companyService
				.getCompanyImportStatus(response.data.menuId)
				.subscribe(
					(res) => {
						// console.log(res)
						if (res.data.state === 'completed') {
							$crawlerObs.unsubscribe()

							let expiryDate = new Date()
							expiryDate.setMonth(expiryDate.getMonth() + 2)

							// console.log(res.data.token)

							this.cookieService.set(
								'ADMIN_TOKEN',
								JSON.stringify(res.data.token),
								expiryDate,
								'/'
							)

							this.loaderService.hide()
							this.mixPanelService.track('manual-register-done')
							this.trackerService.track('IMPORT-COMPANY')
							this.router.navigate(['/thanks'], {
								queryParamsHandling: 'preserve'
							})
						}
						if (res.data.state === 'error') {
							$crawlerObs.unsubscribe()
							this.loaderService.hide()
							this.toast.error(res.data.message)
						}
					},
					({ error }) => {
						$crawlerObs.unsubscribe()

						this.loaderService.hide()
						this.form.enable()
						this.toast.error(error.message)
						return
					}
				)
		} catch (err) {
			this.toast.error(err.error.message)
		}
	}

	public async handleCEPChange(): Promise<void> {
		this.loaderService.show('Buscando endereço...')
		const cepValue = this.form.get('postalCode').value

		// validate cepValue with regex
		if (!cepValue.match(/[0-9]{5}-[\d]{3}/)) {
			this.form.get('postalCode').setErrors({ invalidCEP: true })
			this.cepInputed = false
			this.loaderService.hide()
			return
		}

		const address = await this.cepService
			.getAddressByCep(cepValue)
			.catch((err) => {
				this.form.get('postalCode').setErrors({ CEPnotFounded: true })
				this.toast.error('CEP não encontrado')
				this.cepInputed = false
				return
			})
		this.loaderService.hide()
		if (!address) {
			return
		}
		this.cepInputed = true
		this.form.get('street').setValue(address.street)
		this.form.get('district').setValue(address.neighboorhood)
		this.form.get('city').setValue(address.city)
		this.form.get('state').setValue(address.state)
	}
}
