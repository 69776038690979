<div class="flow-register">

  <app-header></app-header>

  <main class="main-content">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </main>

  <app-footer></app-footer>
</div>