<form [formGroup]="form" novalidate (submit)="handleFormSubmit()" [attr.disabled]="isLoading">
  <div class="card">
    <header class="card-header">
      1 - DADOS PARA PAGAMENTO
    </header>
    <div class="card-body">
      <form action="">
        <app-input label="Nome" placeholder="Digite o seu nome"  [control]="form.controls.name"></app-input>
        <app-input label="CPF ou CNPJ" placeholder="Digite seu CPF ou CNPJ" mask="CPF_CNPJ" [control]="form.controls.cpfOrCnpj"></app-input>
      </form>
    </div>
  </div>

  <footer>
    <p>
      <i class="fa fa-lock"></i>
      Todas as transações de nosso site são seguras
    </p>
    <app-button
      className="btn btn-primary"
      type="submit"
      label="Finalizar meu pedido"
      [isLoading]="isLoading"
      [disabled]="form.invalid"
    ></app-button>
  </footer>
</form>


