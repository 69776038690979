import { Injectable } from '@angular/core'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class TrackersService {
	constructor(private gtmService: GoogleTagManagerService) {}

	public track(eventType: string, eventData?: unknown): void {
		if (!environment.production) return
		const gtmTag = {
			event: eventType,
			data: eventData
		}

		this.gtmService.pushTag(gtmTag)
	}

	public viewPage(url: string): void {
		if (!environment.production) return
		const gtmTag = {
			event: 'page',
			pageName: url
		}
		this.gtmService.pushTag(gtmTag)
	}
}
