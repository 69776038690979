import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { delayWhen, map, retryWhen, timer } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'

const { ANOTAAI_DESKTOP_URL } = environment

@Injectable({
	providedIn: 'root'
})
export class AnotaaiDesktopService {
	constructor(private http: HttpClient) {}

	public verifyAnotaaiDesktop() {
		const url = `${ANOTAAI_DESKTOP_URL}/test`

		return this.http.get<ICommonResponse<any>>(url).pipe(
			map((res: ICommonResponse) => {
				if (!res.success) {
					console.log('Retry')
					throw 'Error'
				}
				return res
			}),
			retryWhen((errors) => {
				return errors.pipe(
					delayWhen(() => {
						return timer(3000)
					})
				)
			})
		)
	}

	public loginOnAnotaaiDesktop(token) {
		const url = `${ANOTAAI_DESKTOP_URL}/login?token=${token}`

		return this.http.get<ICommonResponse<any>>(url).pipe(
			map((res: ICommonResponse) => {
				if (!res.success) {
					console.log('Retry')
					throw 'Error'
				}
				return res
			}),
			retryWhen((errors) => {
				return errors.pipe(
					delayWhen(() => {
						return timer(3000)
					})
				)
			})
		)
	}
}
