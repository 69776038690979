import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { EstablishmentService } from 'src/app/shared/services/establishment/establishment.service'
import { OnboardingService } from '../../services/onboarding.service'

@Component({
	selector: 'app-robot-test',
	templateUrl: './robot-test.component.html',
	styleUrls: ['./robot-test.component.scss']
})
export class RobotTestComponent implements OnInit {
	public stepKey
	public isLoading = false
	public ableToAdvance = false
	public successToast
	constructor(
		public onboardingService: OnboardingService,
		public router: Router,
		public establishmentService: EstablishmentService,
		public toast: HotToastService
	) {
		this.stepKey = this.onboardingService.getStepByPath(this.router.url).key
		this.sendTestMessage()
	}

	ngOnInit(): void {}

	public sendTestMessage() {
		this.isLoading = true
		this.establishmentService.sendTestMessage().subscribe(
			(res) => {
				this.successToast = this.toast.success(
					'Mensagem enviada com sucesso!',
					{
						position: 'top-right',
						dismissible: true,
						autoClose: false
					}
				)
				this.isLoading = false
				this.ableToAdvance = true
			},
			(err) => {
				this.toast.error(
					'Ocorreu um erro ao enviar a mensagem de teste. Tente novamente mais tarde.'
				)
				this.isLoading = false
				this.ableToAdvance = true
			}
		)
	}

	public onNext(): void {
		if (this.successToast) {
			this.successToast.close()
		}
		this.onboardingService.completeStep(this.stepKey)
		const nextStepPath = this.onboardingService.getNextStepPath(this.stepKey)
		this.router.navigate([nextStepPath])
	}
}
