<form [formGroup]="form">
    <header>
        <h1>Link do iFood</h1>
    </header>

    <p>É só colocar o seu link de cardápio do Ifood abaixo que faremos a importação do seu cardápio, horário de
        funcionamento e demais informações necessárias sobre o seu estabelecimento.</p>
    <br>

    <app-input type="text" placeholder="Ex: https://www.ifood.com.br/delivery/guarulhos-sp/pizzaria-do-pe..."
        label="Link do seu cardápio" [control]="form.controls.menu_url">
    </app-input>

    <footer>
        <br>
        <app-button className="btn btn-red-ifood w-full" label="Continuar" (clickEvent)="handleSubmit()"
            [isLoading]="isLoading" [disabled]="form.invalid"></app-button>
    </footer>
    <app-secure-website-tag></app-secure-website-tag>

</form>