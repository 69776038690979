import { Component, Input, OnInit } from '@angular/core'
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router
} from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { ICommonResponse } from 'src/app/shared/interfaces/common-response.interface'
import { IPlan } from 'src/app/shared/services/plans/interfaces/plan.interface'
import { PlansService } from 'src/app/shared/services/plans/plans.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { SaleService } from 'src/app/shared/services/sale/sale.service'

@Component({
	selector: 'app-plans-feed',
	templateUrl: './plans-feed.component.html',
	styleUrls: ['./plans-feed.component.scss']
})
export class PlansFeedComponent implements OnInit {
	@Input() public plans: IPlan[] = []

	period: number = 1
	isLoading: boolean = false

	constructor(
		private route: ActivatedRoute,
		private saleService: SaleService,
		private registerService: RegisterService,
		private router: Router,
		private cookieService: CookieService
	) {}

	ngOnInit(): void {}

	public changePeriod(period: number): void {
		this.period = period
	}

	public changePlan(period: number): void {
		this.period = period
	}

	public async upsertPlan(planId) {
		const snapshot: ActivatedRouteSnapshot = this.route.snapshot
		const token =
			snapshot.queryParams.token ?? this.cookieService.get('ADMIN_TOKEN')

		this.isLoading = true
		this.saleService.updateSalePlanByToken(token, planId).subscribe(
			(res: ICommonResponse) => {
				// console.log(res)
				const saleId = res.data.sale
				this.registerService.updateForm({ saleId })

				const queryParams: Params = { saleId }
				// console.log(queryParams)

				this.router.navigate([`/checkout/payment`], {
					queryParams: queryParams
				})
			},
			(err) => console.log(err),
			() => (this.isLoading = false)
		)
	}
}
