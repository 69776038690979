<form [formGroup]="emailform" (submit)="handleSubmit()" novalidate>
    <header>
        <!-- <img class="logo" src="../../../../../assets/logo.svg"> -->
        <!-- <app-back-button></app-back-button> -->
        <h1>Bem vindo(a)!</h1>
        <h2>Insira seu email para continuar.</h2>
    </header>
    <div class="alert alert-info" *ngIf="userAlreadyExists">
        <p>
          Ops! Parece que você já acessou nosso sistema anteriormente, suas credenciais de acesso foram enviadas para o email <b>{{userEmail}}</b>
        </p>
        <p>
          Você já pode fechar esta janela.
        </p>      
      </div>
    <br>
    <app-input class="w-full" label="Email *" placeholder="Digite seu email para continuar" type="text"  [control]="emailform.controls.email"></app-input>

    <footer>
        <app-button className="btn btn-primary" type="submit" label="Próximo"
          [disabled]="emailform.invalid || userAlreadyExists"></app-button>
      </footer>

</form>