<div class="payment-wrapper" *ngIf="sale">
  <div class="left-container">
    <header>
		<app-back-button></app-back-button>
      <small>PASSO 2 DE 2</small>
      <h1>Efetue o pagamento</h1>
    </header>

    <app-cupon-form [sale]="sale" (onSuccess)="handleUpdateCart($event)" (onSuccessRemove)="handleUpdateCart($event)">
    </app-cupon-form>
    <app-order-overview [cart]="cart" [hideChangePlanButton]="hideChangePlanButton"></app-order-overview>
  </div>
  <div class="right-container">
    <div class="card">
      <header class="card-header">
        1 - SELECIONE O MÉTODO DE PAGAMENTO
      </header>
      <div class="card-body">
        <div class="payment-methods">
          <app-option-card [checked]="paymentMethod === 'CREDIT-CARD-PAYMENT'"
            (click)="handleSelectMethod('CREDIT-CARD-PAYMENT')" label="Cartao de credito"></app-option-card>
          <app-option-card [checked]="paymentMethod === 'BILLET-PAYMENT'" (click)="handleSelectMethod('BILLET-PAYMENT')"
            label="Boleto"></app-option-card>
        </div>
      </div>
    </div>

    <app-credit-card *ngIf="paymentMethod === 'CREDIT-CARD-PAYMENT'" [sale]="sale" [isLoading]="isLoading"
      (onSuccess)="handlePaymentSubmit($event)"></app-credit-card>
    <app-bill *ngIf="paymentMethod === 'BILLET-PAYMENT'" [sale]="sale" [isLoading]="isLoading"
      (onSuccess)="handlePaymentSubmit($event)"></app-bill>
  </div>
</div>