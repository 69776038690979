import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CheckoutComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
