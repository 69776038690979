import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Observable, timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'
import { ICreateCompanyDTO } from './dtos/createCompany.dto'

@Injectable({
	providedIn: 'root'
})
export class CompanyService {
	baseUrl: string

	constructor(private httpClient: HttpClient) {
		this.baseUrl = environment.BACKEND_URL
	}

	createCompany(data): Observable<ICommonResponse<any>> {
		return this.httpClient.post<ICommonResponse<any>>(
			`${this.baseUrl}/noauth/freemium`,
			data
		)
	}

	createIfoodCompany(data, merchantId): Observable<ICommonResponse<any>> {
		return this.httpClient.post<ICommonResponse<any>>(
			`${this.baseUrl}/noauth/ifood`,
			data
		)
	}

	getCompanyImportStatus(importId: string): Observable<ICommonResponse<any>> {
		return timer(1, 3000).pipe(
			switchMap(() =>
				this.httpClient.get<ICommonResponse<any>>(
					`${this.baseUrl}/noauth/freemium/menu/${importId}`
				)
			)
		)
	}
}
