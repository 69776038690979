<div class="modal" *ngIf="modalOpened">
	<div class="modal-container">
		<span (click)="toggleModal()"><i class="fa fa-close"></i></span>
		<div class="modal-header">
			<h3>Aprenda como copiar o link do seu cardápio iFood!</h3>
		</div>
		<div class="modal-body">
			<iframe src="https://www.youtube.com/embed/XsxsaLH3MmI" frameborder="0" allowfullscreen autoplay></iframe>
		</div>
		<div class="modal-footer">
			<app-button className="btn btn-blue-outline" label="Fechar vídeo" (click)="toggleModal()">
			</app-button>
		</div>

	</div>
</div>
<form [formGroup]="form" (submit)="handleSubmit()" novalidate>
	<header>
		<!-- <img class="logo" src="../../../../../assets/logo.svg"> -->
		<app-back-button></app-back-button>
		<h1>Como deseja cadastrar seu restaurante?</h1>
	</header>

	<!-- <app-select-card name="register_method" value="1" label="Importe do iFood"
		subtext="Copie e cole o seu link de cardápio do iFood no campo abaixo para a importação do cardápio e infomações do seu restaurante."
		[control]="form.controls.register_method" [featured]="true" [form]="form">
		<app-input id="appInput" label="Link do seu cardápio iFood *" [control]="form.controls.url"
			placeholder="Ex: https://www.ifood.com.br/delivery/poa-sp/pizzaria-pizza-poa"></app-input>
		<span class="helper">
			<a (click)="toggleModal()"><i>Onde consigo o link da minha loja do iFood?</i></a>
		</span>
	</app-select-card> -->
	<div class="select-card" style="cursor: default;">
		<div class="card-header">
			<div class="left">
				<span class="label">Importar do iFood</span>
			</div>
			<div class="right">
				<span class="badge badge-green">
					Recomendado
				</span>
			</div>
		</div>
		<p>Use seu CNPJ para a importação do cardápio e informações do seu restaurante ou copie e cole o seu link de
			cardápio do iFood.</p>
		<div class="card-inputs">
			<div class="radio-input" (click)="radioSelect('3')">
				<input type="radio" value="3" [formControl]="form.controls.register_method">
				<app-input type="text" placeholder="Ex: XX.XXX.XXX/0001-XX" label="CNPJ *" mask="00.000.000/0000-00"
					[control]="form.controls.cnpj">
				</app-input>
			</div>
			<div class="radio-input" style="margin: 0" (click)="radioSelect('1')">
				<input type="radio" value="1" [formControl]="form.controls.register_method">
				<app-input type="text"
					placeholder="Ex: https://www.ifood.com.br/delivery/pelotas-rs/nosotros-centro/..."
					label="Link do seu cardápio iFood *" [control]="form.controls.url">
				</app-input>
			</div>
		</div>
		<span class="helper">
			<a (click)="toggleModal()"><i>Onde consigo o link da minha loja do iFood?</i></a>
		</span>
	</div>
	<app-select-card name="register_method" value="2" label="Preenchimento manual"
		subtext="Preencha as informações do seu restaurante como: cardápio e horário de funcionamento manualmente."
		[control]="form.controls.register_method" [form]="form" (click)="radioSelect('2')">
	</app-select-card>

	<footer>
		<app-button className="btn btn-primary w-full" type="submit" label="Próximo" [isLoading]="isLoading"
			[disabled]="form.invalid"></app-button>
	</footer>
	<app-secure-website-tag></app-secure-website-tag>
</form>