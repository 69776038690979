<header class="main-header">
  <div class="container">
    <div class="left">
      <a href="https://anota.ai/home"><img src="/assets/logo.svg" alt="Logo" /></a>
    </div>
    <!-- <div class="support-cta">
      <span>Precisa de ajuda?</span>
      <a href="http://" target="_blank" rel="noopener noreferrer" class="underline">Fale com especialista</a>
    </div> -->
  </div>
</header>