// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// BACKEND_URL: 'http://localhost:4000',
	BACKEND_URL: 'https://staging-freemium-api.anota-ai.com',
	// CRM_API_URL: 'http://localhost:3333',
	CRM_API_URL: 'https://staging-crm-api.anota-ai.com',
	TAG_MANAGER_ID: 'GTM-MKQBRXH',
	ANOTAAI_ADMIN_URL: 'https://staging-app.anota-ai.com/admin/index.html#',
	//OLD_BACKEND_URL: 'http://localhost:3000',
	// OLD_BACKEND_URL: 'https://eade-179-189-149-2.ngrok.io',
	OLD_BACKEND_URL: 'https://staging-ssanotaai-back.anota-ai.com',
	OLD_BACKEND_KEY:
		'eyJhbGciOiJIUzI1NiJ9.eyJpZHZlbmRvciI6IjVmNTBlZTFiNWVhMDdjMDAyMjRlNjQxMSJ9.27x5TRNoqjSFp8i0I7vZ3nr-DI6BIhNVjsPy4zmUSmo',
	ANOTAAI_AUTH_BACKEND_URL: 'https://staging-auth.anota-ai.com',
	ANOTAAI_RESPONDE_DOWNLOAD_URL:
		'https://anotaai-responde-staging.s3.amazonaws.com/AnotaAIRespondeStaging-Setup-1.2.3.exe',
	ANOTAAI_RESPONDE_PROTOCOL: 'anotaaistg',
	PRINTER_URL: 'http://127.0.0.1:5000',
	ANOTAAI_DESKTOP_URL: 'http://127.0.0.1:5001',
	NO_COMPUTER_LP: 'https://anota.ai/home/sem-computador',
	NO_COMPUTER_LANDBOT:
		'https://landbot.pro/v3/H-1347512-UC60DYVZAFULB0WN/index.html',
	MIX_PANEL_TRACKER_URL: 'https://staging-system-usage.api.anota-ai.com/action',
	UUID_KEY: '6369373afb084793432f927d',
	BOT_MOBILE_URL: 'https://staging-bot-mobile.anota-ai.com'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
