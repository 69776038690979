export const environment = {
	production: true,
	BACKEND_URL: 'https://freemium.api.anota.ai',
	// BACKEND_URL: 'https://register-api-production.herokuapp.com',
	CRM_API_URL: 'https://crm.api.anota.ai',
	TAG_MANAGER_ID: 'GTM-5WPJWVN',
	ANOTAAI_ADMIN_URL: 'https://app.anota.ai/admin/index.html#',
	OLD_BACKEND_URL: 'https://ssanotaai-back.api.anota.ai',
	OLD_BACKEND_KEY:
		'eyJhbGciOiJIUzI1NiJ9.eyJpZHZlbmRvciI6IjVmNTBlZTFiNWVhMDdjMDAyMjRlNjQxMSJ9.27x5TRNoqjSFp8i0I7vZ3nr-DI6BIhNVjsPy4zmUSmo',
	ANOTAAI_AUTH_BACKEND_URL: 'https://auth.anota.ai',
	ANOTAAI_RESPONDE_DOWNLOAD_URL:
		'https://anotaai-responde-beta.s3.amazonaws.com/AnotaAIResponde-Setup-1.2.15.exe',
	ANOTAAI_RESPONDE_PROTOCOL: 'anotaai',
	PRINTER_URL: 'http://127.0.0.1:5000',
	ANOTAAI_DESKTOP_URL: 'http://127.0.0.1:5001',
	NO_COMPUTER_LP: 'https://anota.ai/home/sem-computador',
	NO_COMPUTER_LANDBOT:
		'https://landbot.pro/v3/H-1347512-UC60DYVZAFULB0WN/index.html',
	UUID_KEY: '63723998edc268dc340920b6',
	MIX_PANEL_TRACKER_URL: 'https://system-usage.api.anota.ai/action',
	BOT_MOBILE_URL: 'https://bot-mobile.anota.ai'
}
