import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CompanyService } from 'src/app/shared/services/company/company.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { UtmService } from 'src/app/shared/services/utm/utm.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-menu-slug',
	templateUrl: './menu-slug.component.html',
	styleUrls: ['./menu-slug.component.scss']
})
export class MenuSlugComponent implements OnInit {
	public form: FormGroup
	public isLoading = false
	public formData

	constructor(
		private loaderService: LoaderService,
		private cookieService: CookieService,
		private router: Router,
		private utmService: UtmService,
		private crmApiService: CrmApiService,
		private companyService: CompanyService,
		private toastService: HotToastService,
		private mixPanelService: MixPanelService
	) {}

	ngOnInit(): void {
		this.initializeForm()
		const cookieFormData = this.cookieService.get('ifood-form-data')
		if (!cookieFormData) {
			this.router.navigate(['ifood/account-info'], {
				queryParamsHandling: 'preserve'
			})
			return
		}
		this.formData = JSON.parse(cookieFormData)
	}

	public initializeForm(): void {
		this.form = new FormGroup({
			menu_url: new FormControl('', [Validators.required])
		})
	}

	public getDevice(): 'mobile' | 'desktop' {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	public wait(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms))
	}

	public async handleSubmit() {
		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return
		}
		this.form.disable()
		this.isLoading = true
		this.loaderService.show([
			'Aguarde enquanto cadastro seu cardápio - é rapidinho!',
			'Deixa eu dar uma olhada nos seus produtos',
			'Espera mais um pouquinho que estamos importando os dados',
			'Estamos revisando se há algum erro de ortografia',
			'Estou cadastrando seus horários de funcionamento',
			'Deixa só eu terminar de cadastrar o endereço do seu restaurante',
			'Pronto, agora vou começar a configurar seu robozinho'
		])
		try {
			const utms = this.utmService.getUtms()
			let deal_id

			const dealDTO = {
				...this.form.value,
				...this.formData,
				...utms,
				company: this.formData['page_name'],
				computer: 'Sim',
				analytics_id: this.mixPanelService.analyticsId,
				anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
				site_origem: 'Trial Anota AI - Ifood Merchant'
			}
			// removing undefined fields
			Object.keys(dealDTO).forEach((key) =>
				dealDTO[key] == null || dealDTO[key] == 'undefined'
					? delete dealDTO[key]
					: {}
			)
			const {
				data: { dealId }
			} = await this.crmApiService
				.upsertIfoodPartnerDeal(dealDTO, true)
				.toPromise()

			while (true) {
				const getDeal = await this.crmApiService.getCrmDeal(dealId).toPromise()
				console.log(getDeal)
				if (getDeal.data.externalIds.length) {
					const externalDealId = getDeal.data.externalIds.find(
						(externalId) => externalId?.key === 'MOSKIT'
					)
					if (externalDealId?.value) {
						deal_id = externalDealId.value
						break
					}
				}
				await this.wait(3000)
			}

			const ifoodMerchantDTO = {
				name: this.formData.first_name,
				phone: this.formData.phone,
				email: this.formData.email,
				password: this.formData.password,
				page_name: this.formData.page_name,
				cpf_cnpj: this.formData.document,
				...utms,
				device_category: this.getDevice(),
				anotaai_reponde_version: environment.ANOTAAI_RESPONDE_DOWNLOAD_URL,
				site_origem: 'Trial Anota AI - Ifood Merchant',
				dealId: deal_id,
				isFromPartnerLP: true,
				analytics_id: this.mixPanelService.analyticsId
			}
			const ifood_menu_slug = this.form
				.get('menu_url')
				.value.split('https://www.ifood.com.br/delivery/')[1]

			const ifoodMerchantIdSplited = ifood_menu_slug.split('/')
			if (ifoodMerchantIdSplited.length > 2) {
				ifoodMerchantDTO.ifood_merchant_id =
					ifoodMerchantIdSplited[ifoodMerchantIdSplited.length - 1]
			}

			// removing undefined fields
			Object.keys(ifoodMerchantDTO).forEach((key) =>
				ifoodMerchantDTO[key] == null || ifoodMerchantDTO[key] == 'undefined'
					? delete ifoodMerchantDTO[key]
					: {}
			)

			let response
			response = await this.companyService
				.createCompany(ifoodMerchantDTO)
				.toPromise()

			if (!response.success) {
				this.toastService.error(response.message)
				this.loaderService.hide()
				this.form.enable()
				this.isLoading = false
				return
			}
			const $crawlerObs = this.companyService
				.getCompanyImportStatus(response.data.menuId)
				.subscribe((res) => {
					// console.log(res)
					if (res.data.state === 'completed') {
						$crawlerObs.unsubscribe()

						let expiryDate = new Date()
						expiryDate.setMonth(expiryDate.getMonth() + 2)

						// console.log(res.data.token)

						this.cookieService.set(
							'ADMIN_TOKEN',
							JSON.stringify(res.data.token),
							expiryDate,
							'/'
						)

						this.loaderService.hide()
						this.isLoading = false

						this.router.navigate(['/thanks'], {
							queryParamsHandling: 'preserve'
						})
					}
					if (res.data.state === 'error') {
						$crawlerObs.unsubscribe()
						this.form.enable()
						this.isLoading = false
						this.toastService.error(res.data.message)
						this.loaderService.hide()
					}
				})
		} catch (err) {
			console.log('err', err)
			let replyMessage = 'Ops! Ocorreu um erro. Tente novamente!'
			if (err.error?.message) {
				replyMessage = err.error.message
			}
			this.isLoading = false
			this.toastService.error(replyMessage)
			this.loaderService.hide()
		}
	}
}
