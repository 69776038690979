import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'

@Injectable({
	providedIn: 'root'
})
export class DealService {
	constructor(
		private cookieService: CookieService,
		private route: ActivatedRoute
	) {}

	getDeal(): { dealId: string; moskitDealId: string } {
		const dealIdsOnCookies = this.cookieService.get('DEAL_IDS')
		const dealIds = dealIdsOnCookies && JSON.parse(dealIdsOnCookies)
		const dealIdOnQuery = this.route.snapshot.queryParamMap.get('id_deal')

		return {
			dealId: dealIdOnQuery ?? dealIds?.dealId,
			moskitDealId: dealIds.moskitDealId
		}
	}

	setDeal(dealIds?: { dealId: string; moskitDealId?: string }): void {
		if (!dealIds) {
			this.cookieService.delete('DEAL_IDS')
			return
		}

		let expiryDate = new Date()
		expiryDate.setMonth(expiryDate.getMonth() + 2)

		this.cookieService.set('DEAL_IDS', JSON.stringify(dealIds), expiryDate, '/')
	}
}
