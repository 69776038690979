import { Injectable } from '@angular/core'
import { ICart } from './interfaces/cart.interface'

@Injectable({
	providedIn: 'root'
})
export class CartService {
	constructor() {}

	initCart(planName: string, recurrence: number, membershipFee: number): ICart {
		this.updateCart({
			planName: planName,
			recurrence: {
				value: recurrence,
				total: recurrence
			},
			membershipFee: {
				value: membershipFee,
				total: membershipFee - 200,
				discount: { value: 200, type: 0 }
			},
			totalSale: recurrence + membershipFee - 200,
			totalDiscount: 0
		})

		return this.getCart()
	}

	getCart(): ICart {
		const getSessionCart = sessionStorage.getItem('@anota-ai/initiate/cart')

		return JSON.parse(getSessionCart) ?? {}
	}

	updateCart(data: object) {
		const cart = this.getCart()
		const result = Object.assign(cart, data)

		sessionStorage.setItem('@anota-ai/initiate/cart', JSON.stringify(result))
	}

	addCupon(cuponCode: string, cupon: any) {
		const cart = this.getCart()

		cart.recurrence.discount = {
			value: Math.round(
				cupon.recurrence.type === 0
					? cupon.recurrence.value
					: cart.recurrence.value * (cupon.recurrence.value / 100)
			),
			type: cupon.recurrence.type
		}
		cart.recurrence.total = Math.round(
			cart.recurrence.value - cart.recurrence.discount.value
		)

		cart.membershipFee.discount = {
			value: Math.round(
				cupon.membershipFee.type === 0
					? cupon.membershipFee.value
					: cart.membershipFee.value * (cupon.membershipFee.value / 100)
			),
			type: cupon.membershipFee.type
		}
		cart.membershipFee.total = Math.round(
			cart.membershipFee.value - cart.membershipFee.discount.value
		)

		;(cart.totalSale = Math.round(
			cart.recurrence.total + cart.membershipFee.total
		)),
			(cart.totalDiscount = Math.round(
				cart.recurrence.discount.value + cart.membershipFee.discount.value
			))
		// console.log(cart)
		this.updateCart(cart)
	}

	removeCupon() {
		const cart = this.getCart()

		this.updateCart({
			cuponCode: null,
			recurrence: {
				value: cart.recurrence.value,
				discount: 0,
				total: cart.recurrence.value
			},
			membershipFee: {
				value: cart.membershipFee.value,
				discount: 200,
				total: cart.membershipFee.value
			},
			totalSale: cart.recurrence.total + cart.membershipFee.total,
			totalDiscount: 0
		})
	}
}
