import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { CartService } from 'src/app/shared/services/cart/cart.service'
import { CuponService } from 'src/app/shared/services/cupon/cupon.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { ISale } from 'src/app/shared/services/sale/interfaces/sale.interface'
import { SaleService } from 'src/app/shared/services/sale/sale.service'

@Component({
	selector: 'app-cupon-form',
	templateUrl: './cupon-form.component.html',
	styleUrls: ['./cupon-form.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CuponFormComponent implements OnInit {
	@Output() onSuccess: EventEmitter<any> = new EventEmitter()
	@Output() onSuccessRemove: EventEmitter<any> = new EventEmitter()
	@Input() sale: ISale
	@Input() isFromAccordion: boolean
	@Input() isLoading = false

	form: FormGroup
  hasCupon: boolean

	constructor(
    private toastService: HotToastService,
    private cartService: CartService,
    private saleService: SaleService,
    private registerService: RegisterService,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {}

	ngOnInit(): void {
		this.initializeForm()
	}

	initializeForm(): void {
		this.form = new FormGroup({
			cuponCode: new FormControl({value:'TRIAL_100_ADESAO', disabled: true}, [],)
		})
	}

  async handleRemoveCupon() {
    this.hasCupon = false
    this.cartService.removeCupon()
    this.form.setValue({ cuponCode: '' });
    this.form.setErrors(null);
    this.form.enable();

    this.isLoading = true

    const token = this.route.snapshot.queryParams.token ?? this.cookieService.get('ADMIN_TOKEN')
    await this.saleService.deleteSaleCupon(token).toPromise()

    this.isLoading = false

    this.onSuccessRemove.emit()
  }

	async handleFormSubmit(): Promise<boolean> {
		window.scrollTo(0, 0)

		if (this.form.invalid) {
			this.form.markAllAsTouched()
			return false
		}

    this.form.disable();
    this.isLoading = true;

    const cuponCode = this.form.get('cuponCode').value;

    const token = this.route.snapshot.queryParams.token ?? this.cookieService.get('ADMIN_TOKEN')
    const response = await this.saleService.updateSaleCuponByToken(token, cuponCode).toPromise().catch(({ error }) => {
      this.toastService.warning(error.message);
      this.form.setValue({ cuponCode: '' });
      this.form.enable();
      this.isLoading = false;
    })

    this.cartService.addCupon(cuponCode, response.data.cupon);
    this.hasCupon = true;

    this.onSuccess.emit();

    this.isLoading = false;
	}
}
