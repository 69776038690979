import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { EstablishmentService } from 'src/app/shared/services/establishment/establishment.service'
import { environment } from 'src/environments/environment'
import { OnboardingService } from '../../services/onboarding.service'

const { ANOTAAI_ADMIN_URL } = environment
@Component({
	selector: 'app-intro',
	templateUrl: './intro.component.html',
	styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
	public stepKey

	constructor(
		public onboardingService: OnboardingService,
		public router: Router,
		public route: ActivatedRoute,
		private establishmentService: EstablishmentService
	) {
		const path = new URL(window.location.href).pathname
		this.stepKey = this.onboardingService.getStepByPath(path).key
	}

	ngOnInit(): void {
		const token =
			this.route.snapshot.queryParamMap.get('access') ||
			localStorage.getItem('adminToken')
		if (!token) {
			window.location.href = ANOTAAI_ADMIN_URL
			// verify after if token is valid
			return
		}
		localStorage.setItem('adminToken', token)
		this.establishmentService.getEstablishment().subscribe(
			(res) => {
				console.log('establishment', res)
				if (res.not_open_printer == true) {
					console.log('not_open_printer')
					this.onboardingService.removeStep({ path: '/onboarding/printer' })
				}
				if (res.freemium.active) {
					this.onboardingService.removeStep({ path: '/onboarding/robot-test' })
				}
			},
			(err) => {
				console.log('error', err)
			}
		)
	}

	public onNext(): void {
		this.onboardingService.completeStep(this.stepKey)
		const nextStepPath = this.onboardingService.getNextStepPath(this.stepKey)
		this.router.navigate([nextStepPath])
	}
}
