import { AfterViewInit, Component } from '@angular/core'
import { OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { TrackersService } from './shared/services/trackers/trackers.service'
import { PausableObservable, pausable } from 'rxjs-pausable'
import { Subject, interval, Observable } from 'rxjs'
import { ConfettiService } from './shared/services/confetti/confetti.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	paused = true
	pausable: PausableObservable<number>

	constructor(
		private router: Router,
		private trackersService: TrackersService,
		private confettiService: ConfettiService
	) {}

	public ngOnInit(): void {
		this.confettiService.state.subscribe((state) => {
			this.toggle()
		})
		this.router.events.forEach((item) => {
			if (item instanceof NavigationEnd) {
				this.trackersService.viewPage(item.url)
			}
		})

		this.shoot()
		const observable = interval(800) as any
		this.pausable = observable.pipe(pausable()) as PausableObservable<number>
		this.pausable.subscribe(this.shoot.bind(this))
		this.pausable.pause()
	}

	toggle(timer = 3000) {
		if (this.paused) {
			if (!this.pausable) return
			this.pausable.resume()
			setTimeout(() => {
				this.confettiService.toggleConfetti()
				if (!this.paused) {
					this.paused = true
				}
			}, timer)
		} else {
			this.pausable.pause()
		}
		this.paused = !this.paused
	}

	shoot() {
		if (!this.paused) {
			try {
				this.confetti({
					angle: this.random(60, 120),
					spread: this.random(10, 50),
					particleCount: this.random(40, 50),
					origin: {
						y: 0.6,
						x: 0.6
					}
				})
			} catch (e) {
				// noop, confettijs may not be loaded yet
			}
		}
	}

	random(min: number, max: number) {
		return Math.random() * (max - min) + min
	}

	confetti(args: any) {
		return window['confetti'].apply(this, arguments)
	}
}
