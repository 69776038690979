import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

const { ANOTAAI_AUTH_BACKEND_URL } = environment

@Injectable({
	providedIn: 'root'
})
export class SendCredentialsService {
	constructor(private http: HttpClient) {}

	sendCredentials(token: string) {
		const url = `${ANOTAAI_AUTH_BACKEND_URL}/auth/establishment/installation/send-credentials`
		return this.http.get(url, { headers: { authorization: token } })
	}
}
