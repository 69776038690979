<form [formGroup]="form" (submit)="handleSubmit()" novalidate>
	<header>
		<!-- <img class="logo" src="../../../../../assets/logo.svg"> -->
		<h1>Informações da Conta</h1>
	</header>

	<div class="alert alert-info" *ngIf="userAlreadyExists">
		<p>
			Ops! Parece que você já acessou nosso sistema anteriormente, suas
			credenciais de acesso foram enviadas para o email <b>{{userEmail}}</b>
		</p>
	</div>

	<app-input
		type="text"
		placeholder="Insira seu nome"
		label="Seu nome *"
		[control]="form.controls.first_name"
	>
	</app-input>

	<div class="row">
		<app-input
			class="col-md"
			type="text"
			placeholder="Insira seu telefone"
			label="Telefone *"
			mask="(00) 00000-0000"
			[control]="form.controls.phone"
		></app-input>

		<app-input
			class="col-md"
			type="text"
			placeholder="Insira seu email"
			label="Email *"
			[control]="form.controls.email"
		></app-input>
	</div>

	<app-input
		type="text"
		placeholder="Insira o nome do seu delivery de comida"
		label="Nome do estabelecimento *"
		[control]="form.controls.company"
	></app-input>

	<div class="row">
		<app-select
			class="input-component"
			class="col-md"
			[label]="'Quantidade de vendas por dia *'"
			[control]="form.controls.order_day"
			[options]="[
      {
        label: '1 a 10',
        value: '1 a 10'
      },
      {
        label: '11 a 20',
        value: '11 a 20'
      },
      {
        label: '21 a 30',
        value: '21 a 30'
      },
      {
        label: '31 a 40',
        value: '31 a 40'
      },
      {
        label: '41 a 50',
        value: '41 a 50'
      },
      {
        label: '50 a 100',
        value: '50 a 100'
      },
      {
        label: 'Mais que 100',
        value: 'Mais que 100'
      }
    ]"
		></app-select>

		<app-select
			class="input-component"
			class="col-md"
			[label]="'Possui computador ou notebook *'"
			[control]="form.controls.computer"
			[options]="[
        {
          label: 'Sim',
          value: 'Sim'
        },
        {
          label: 'Não',
          value: 'Não'
        }
      ]"
		></app-select>
	</div>

	<span>
		Ao clicar no botão 'Continuar', você aceita nossos
		<a href="https://anota.ai/termos/Termos_de_Uso.pdf" target="_blank"
			>termos de uso</a
		>
		e
		<a
			href="https://anota.ai/politicas/Politica_de_Privacidade.pdf"
			target="_blank"
			>política de privacidade</a
		>.
	</span>

	<footer>
		<app-button
			className="btn btn-primary"
			type="submit"
			label="Próximo"
			[isLoading]="isLoading"
			[disabled]="form.invalid || disableButton"
		></app-button>
	</footer>

	<app-secure-website-tag></app-secure-website-tag>
</form>
