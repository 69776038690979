import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject } from 'rxjs'

export interface OnboardingStep {
	key?: number
	title: string
	description: string
	active: boolean
	started: boolean
	completed: boolean
	path: string
}

@Injectable({
	providedIn: 'root'
})
export class OnboardingService {
	public steps: OnboardingStep[] = [
		{
			title: 'Inicie a instalação',
			description: 'Create a new account',
			active: true,
			started: true,
			completed: false,
			path: '/onboarding'
		},
		{
			title: 'Ative seu Robô',
			description: 'Create a new account',
			active: false,
			started: false,
			completed: false,
			path: '/onboarding/activate-robot'
		},
		{
			title: 'Teste o Robô',
			description: 'Create a new account',
			active: false,
			started: false,
			completed: false,
			path: '/onboarding/robot-test'
		},
		{
			title: 'Verifique Impressora',
			description: 'Create a new account',
			active: false,
			started: false,
			completed: false,
			path: '/onboarding/printer'
		},
		{
			title: 'Concluído',
			description: 'Create a new account',
			active: false,
			started: false,
			completed: false,
			path: '/onboarding/finish'
		}
	]

	public activeStep: BehaviorSubject<OnboardingStep> = new BehaviorSubject(
		this.steps.find((item: OnboardingStep) => item.active)
	)

	constructor(private cookieService: CookieService) {
		this.steps.map(
			(item: OnboardingStep) => (item.key = this.steps.indexOf(item) + 1)
		)
	}

	public getSteps(): OnboardingStep[] {
		const stepsOnCookies = this.cookieService.get('anotaai_onboarding')
		if (stepsOnCookies) {
			this.steps = JSON.parse(stepsOnCookies)
		}
		const activeStep = this.steps.find((item: OnboardingStep) => item.active)
		this.activeStep.next(activeStep)

		return this.steps
	}

	public getStepByPath(path: string): OnboardingStep {
		return this.steps.find((item: OnboardingStep) => item.path === path)
	}

	public completeStep(stepKey: number) {
		this.steps.map((item: OnboardingStep) => {
			if (item.key === stepKey) {
				if (stepKey === this.steps.length) {
					item.completed = true
					return
				}
				item.active = false
				item.completed = true
			}

			if (item.key === stepKey + 1) {
				item.active = true
				item.started = true
				this.activeStep.next(item)
			}
		})
		this.cookieService.set('anotaai_onboarding', JSON.stringify(this.steps))
	}

	public getNextStepPath(stepKey: number) {
		for (let i = 0; i < this.steps.length; i++) {
			if (this.steps[i].key === stepKey + 1) {
				return this.steps[i].path
			}
		}
	}

	public changeActiveStep(stepKey: number): void {
		this.steps.map((item: OnboardingStep) => {
			if (item.key === stepKey) {
				item.active = true
				this.activeStep.next(item)
			} else {
				item.active = false
			}
		})
	}

	public removeStep(step: Partial<OnboardingStep>): void {
		const hasFormInCookies = this.cookieService.get('anotaai_onboarding')
		if (hasFormInCookies) {
			return
		}
		this.steps = this.steps.filter(
			(item: OnboardingStep) =>
				item.key !== step.key &&
				item.title !== step.title &&
				item.description !== step.description &&
				item.path !== step.path &&
				item.active !== step.active &&
				item.started !== step.started &&
				item.completed !== step.completed
		)
		this.steps.map(
			(item: OnboardingStep) => (item.key = this.steps.indexOf(item) + 1)
		)
	}
}
