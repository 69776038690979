import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { CartService } from 'src/app/shared/services/cart/cart.service'
import { ICart } from 'src/app/shared/services/cart/interfaces/cart.interface'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { DealService } from 'src/app/shared/services/deal/deal.service'
import { RegisterService } from 'src/app/shared/services/register/register.service'
import { IPaySaleDTO } from 'src/app/shared/services/sale/dtos/pay-sale.dto'
import { ISale } from 'src/app/shared/services/sale/interfaces/sale.interface'
import { SaleService } from 'src/app/shared/services/sale/sale.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
	public sale: ISale
  public cart: ICart
	public paymentMethod: 'CREDIT-CARD-PAYMENT' | 'BILLET-PAYMENT' =
		'CREDIT-CARD-PAYMENT'
	public isLoading: boolean
	public hideChangePlanButton: boolean = false

	constructor(
		private registerService: RegisterService,
		private router: Router,
		private saleService: SaleService,
		private toast: HotToastService,
		private crmApiService: CrmApiService,
		private dealService: DealService,
		private trackerService: TrackersService,
		private route: ActivatedRoute,
    private cartService: CartService,
		private cookieService: CookieService
	) {}

	ngOnInit(): void {
		this.checkToken()
		this.checkSale()
	}

	public checkToken() {
		const token = this.cookieService.get('ADMIN_TOKEN')
		this.hideChangePlanButton = !token
	}

	async checkSale(): Promise<void> {
		const { saleId } =
			this.route.snapshot.queryParams || this.registerService.getForm()

		if (!saleId) {
			this.router.navigate(['/'])
			return
		}

		const { data } = await this.saleService
			.getSaleById(saleId)
			.toPromise()
			.catch(() => {
				this.router.navigate(['/'])
				return
			})

      this.cart = this.cartService.initCart(data.plan.title, data.value_plan, data.membershipFee)

		this.sale = {
			_id: data._id,
			name: data.user.name,
			email: data.user.email,
			phone: data.user.phone,
			cpf: data.user.cpf,
			birthDate: data.user.birthDate,
			cpf_cnpj: data.user.cpf,
			cupon: data.cupon,
			address: data.user.address,
			dealId: data.deal_id,
			establishmentName: data.page_name,
			max_parcel: data.max_parcel,
			membershipFee: data.membershipFee,
			plan_id: data.plan._id,
			plan_name: data.plan.title,
			value_plan: data.plan.price,
			vendorId: data.vendor,
			period: data.plan.period,
			external_plan_id: data.plan.external_id
		}
	}

	handleSelectMethod(method: 'CREDIT-CARD-PAYMENT' | 'BILLET-PAYMENT'): void {
		this.paymentMethod = method
	}

	async handlePaymentSubmit(data): Promise<void> {
		const loadingToastRef = this.toast.loading(
			'Estamos processando seu pagamento.'
		)

		try {
			const { _id, name, email, phone, address } = this.sale
			const { dealId } = this.dealService.getDeal()

			let saleData = {} as IPaySaleDTO

			const paymentMethod = this.paymentMethod

			if (paymentMethod === 'BILLET-PAYMENT') {
				this.crmApiService.insertNote(dealId, 'Tentou pagar com o Boleto')

				saleData = {
					_id,
					name,
					email,
					phone: String(phone),
					address: {
						zipCode: address.postalCode,
						street: address.street,
						number: String(address.number),
						complement: address.complement,
						district: address.district,
						cityName: address.city,
						stateInitials: address.state,
						countryName: 'Brazil'
					},
					identity: data.cpfOrCnpj,
					paymentType: 0
				}
			}

			if (paymentMethod === 'CREDIT-CARD-PAYMENT') {
				this.crmApiService.insertNote(
					dealId,
					'Tentou pagar com cartão de crédito'
				)

				const upperCasedName = data.name.toUpperCase()
				const formatedCardNumber = data.cardNumber.replace(/\s/g, '')
				const formatedDueDate = data.validity.replace('/', '/20')

				saleData = {
					_id,
					name,
					email,
					phone: String(phone),
					identity: data.cpfOrCnpj,
					address: {
						zipCode: address.postalCode,
						street: address.street,
						number: String(address.number),
						complement: address.complement,
						district: address.district,
						cityName: address.city,
						stateInitials: address.state,
						countryName: 'Brazil'
					},
					payment_method: {
						cardName: upperCasedName,
						securityCode: data.cvv,
						dueDate: formatedDueDate,
						cardNumber: formatedCardNumber,
						identity: data.cpfOrCnpj
					},
					installmentQuantity: data.parcels,
					paymentType: 3
				}
			}

			const getPaymentDataRequest = await this.saleService
				.pay(paymentMethod, saleData)
				.toPromise()

			if (!getPaymentDataRequest.success) {
				this.toast.error(
					getPaymentDataRequest.message ?? 'Erro ao realizar pagamento.'
				)
				return
			}

			if (paymentMethod === 'BILLET-PAYMENT') {
				window.location.href = getPaymentDataRequest.paymentLink
			}

			if (paymentMethod === 'CREDIT-CARD-PAYMENT') {
        this.router.navigate(['/thanks'], { queryParamsHandling: 'preserve' })

				this.trackerService.track('FINISH-SALE', {
					value: this.sale.value_plan
				})
			}

			loadingToastRef.close()
			this.isLoading = false
		} catch (e) {
			this.toast.error('Erro ao realizar pagamento. Contate um vendedor!')
		}
	}

	handleUpdateCart(): void {
		this.cart = this.cartService.getCart()
	}
}
