<app-loader *ngIf="!plans.length"></app-loader>
<div class="plans-feed">
	<ng-container *ngIf="!plans.length">
		<div class="skeleton"></div>
		<div class="skeleton"></div>
		<div class="skeleton"></div>
	</ng-container>

	<ng-container *ngIf="plans.length">
		<app-plans-feed-card
			*ngFor="let plan of plans"
			[plan]="plan"
			[period]="period"
			(changePeriod)="changePeriod($event)"
			(selectPlan)="upsertPlan($event)"
      [isLoading]="isLoading"
			[hideTrialButton]="hideTrialButton"
		></app-plans-feed-card>
	</ng-container>
</div>
