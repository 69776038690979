<main>
	<div>
		<h1 class="title"><strong>{{activeStep.title}}</strong></h1>
		<p class="step">
			<span>Configurações Iniciais</span>
			<span><svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 0L3.5 3.5L0 7V0Z" fill="#D1D1D1" />
				</svg>
			</span>
			<span><strong>{{activeStep.title}}</strong></span>
		</p>
	</div>
	<div class="onboarding-container">
		<div class="menu">
			<div class="menu-step" *ngFor="let step of onboardingService.steps"
				[ngClass]="[step.active ? 'active': '', step.completed ? 'completed': '']"
				[ngStyle]="{'cursor': step.completed || step.active || step.started ? 'pointer' : 'default'}"
				(click)="changeActiveStep(step)">
				<span>{{step.key}}.</span>
				<span>{{step.title}}</span>
				<span class="spacer"></span>
				<i *ngIf="step.active" [ngClass]="'icon-arrow-right'"></i>
			</div>
		</div>
		<div class="onboarding-content">
			<router-outlet></router-outlet>
		</div>
	</div>
</main>