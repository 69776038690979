import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'
import { IUser } from './interfaces/user.interface'

@Injectable({
	providedIn: 'root'
})
export class UserService {
	baseUrl: string

	constructor(private httpClient: HttpClient) {
		this.baseUrl = environment.BACKEND_URL
	}

	async checkEmail(email: string) {
		try {
			const { success } = await this.httpClient
				.get<ICommonResponse<IUser>>(
					`${this.baseUrl}/noauth/freemium/validate-email?email=${email}`
				)
				.toPromise()

			return success
		} catch (error) {
			return false
		}
	}
	
	async checkEmailRemarket(email: string) {
		try{
			const res = await this.httpClient
				.get<ICommonResponse<IUser>>(`${this.baseUrl}/noauth/freemium/validate-email?email=${email}`)
				.toPromise()
				return res
		} catch (error) {
			return {success: false, data: null}
		}
	}
}
