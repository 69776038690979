import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { delayWhen, map, retryWhen, throwError, timer } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ICommonResponse } from '../../interfaces/common-response.interface'
import { IPrinter } from './interfaces/printer.interface'

const { PRINTER_URL } = environment

@Injectable({
	providedIn: 'root'
})
export class PrinterService {
	constructor(private http: HttpClient) {}

	// listAvailablePrinters() {
	// 	const url = `${PRINTER_URL}/list`
	// 	return this.http.get(url)
	// }

	public listAvailablePrinters() {
		const url = `${PRINTER_URL}/list`

		return this.http.get<ICommonResponse<IPrinter>>(url).pipe(
			map((res: any) => {
				if (!res.printers) {
					throw 'Error'
				}
				return res
			}),
			retryWhen((errors) => {
				return errors.pipe(
					delayWhen(() => {
						return timer(3000)
					})
				)
			})
		)
	}

	public selectPrinter(printerName: string) {
		const url = `${PRINTER_URL}/config`
		return this.http.post(url, { printerName: [printerName] })
	}

	public testPrinter() {
		const url = `${PRINTER_URL}/test`
		return this.http.get(url)
	}
}
