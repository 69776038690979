<form [formGroup]="form" (submit)="handleSubmit()">
  <header>
    <h1>Informações da Conta</h1>
  </header>

  <div class="alert alert-info" *ngIf="userAlreadyExists">
    Este e-mail já está cadastrado em nosso sistema.<br />
    Faça download do nosso Gestor de Pedidos para fazer login.
    <a (click)="redirectToLogin()">Baixar</a>
  </div>

  <app-input type="text" placeholder="Seu nome" label="Insira seu nome *" [control]="form.controls.first_name">
  </app-input>

  <app-input type="text" placeholder="Nome do seu estabelecimento" label="Insira o nome do seu estabelecimento *"
    [control]="form.controls.page_name">
  </app-input>

  <app-input type="text" placeholder="Insira o seu CNPJ" label="Insira seu CNPJ utilizado no iFood *"
    mask="00.000.000/0000-00" [control]="form.controls.document"></app-input>

  <app-input type="text" placeholder="Insira seu telefone" label="Telefone *" mask="(00) 00000-0000"
    [control]="form.controls.phone"></app-input>


  <app-input type="text" placeholder="Insira seu email" label="Email *" [control]="form.controls.email"></app-input>

  <div class="row">
    <app-input class="col-md" type="password" placeholder="Insira sua senha" label="Senha *"
      [control]="form.controls.password">
    </app-input>

    <app-input class="col-md" type="password" placeholder="Confirme sua senha" label="Confirmar senha *"
      [control]="form.controls.passwordConfirmation"></app-input>
  </div>

  <app-select *ngIf="journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-NOT-REQUIRED'] ||
    journeyCases['IFOOD-PARTNERSHIP-MENU-SLUG-REQUIRED']" class="input-component" class="col-md"
    [label]="'Quantidade de vendas por dia *'" [control]="form.controls.order_day" [options]="[
            {
              label: '1 a 10',
              value: '1 a 10'
            },
            {
              label: '11 a 20',
              value: '11 a 20'
            },
            {
              label: '21 a 30',
              value: '21 a 30'
            },
            {
              label: '31 a 40',
              value: '31 a 40'
            },
            {
              label: '41 a 50',
              value: '41 a 50'
            },
            {
              label: '50 a 100',
              value: '50 a 100'
            },
            {
              label: 'Mais que 100',
              value: 'Mais que 100'
            }
          ]"></app-select>
  <span>
    Ao clicar no botão 'Continuar', você aceita nossos
    <a href="https://anota.ai/termos/Termos_de_Uso.pdf" target="_blank">termos de uso</a>
    e
    <a href="https://anota.ai/politicas/Politica_de_Privacidade.pdf" target="_blank">política de
      privacidade</a>.
  </span>

  <footer>
    <br>
    <app-button className="btn btn-red-ifood w-full" label="Continuar" [isLoading]="isLoading"
      (clickEvent)="handleSubmit()" [disabled]="form.invalid"></app-button>
  </footer>
  <app-secure-website-tag></app-secure-website-tag>


</form>