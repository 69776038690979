<article class="plans-feed-card card" [ngClass]="{'featured': plan.isMostRecommended}">
    <div class="card-header">
      <h1>{{plan.name}}</h1>
      <span class="tag tag-orange" *ngIf="plan.isMostRecommended">
        Recomendado
      </span>
    </div>
    <div class="card-body">
      <p>{{plan.subtitle}}</p>

      <div class="period-switch">
        <button [ngClass]="{'active': period === 1}" (click)="changePeriodHandler(1)">Mensal</button>
        <button [ngClass]="{'active': period === 6}" (click)="changePeriodHandler(6)">Semestral</button>
        <button [ngClass]="{'active': period === 12}" (click)="changePeriodHandler(12)">Anual</button>
      </div>

      <div class="pricing">
        <small>{{ plan.prices[period].price | currency:'R$':true }} à vista ou</small>
        <h2 [ngClass]="{'featured': plan.isMostRecommended}">
          {{ plan.prices[period].price / period | currency:'R$':true }} <span *ngIf="period !== 1">em {{ period }}x</span>
        </h2>
      </div>

      <app-button
        label="Selecionar"
        [className]="plan.isMostRecommended ? 'btn btn-primary w-full' : 'btn btn-secondary w-full'"
        label="Contratar"
        (click)="selectPlanHandler(plan)"
        [isLoading]="isLoading"
      ></app-button>
    </div>
    <div class="card-extra">
      <div class="features">
        <header>O QUE VOCÊ RECEBE</header>

        <div *ngFor="let item of plan.benefits" class="feature" [ngClass]="{'active': item.isAvailable}">
          <i [class]="item.isAvailable ? 'fa fa-check' : 'fa fa-close'"></i>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>

    <button>
      <i class="fa fa-phone"></i>
      <span>Fale com um atendente</span>
    </button>
</article>
