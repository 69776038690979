<form [formGroup]="form" novalidate (submit)="handleFormSubmit()" class="cupon-form">
  <!-- <app-input placeholder="TRIAL_100_ADESAO" [control]="form.controls.cuponCode"></app-input> -->
  <app-input placeholder="Codigo do cupom" [control]="form.controls.cuponCode"></app-input>
  <app-button
    *ngIf="!hasCupon"
    className="btn btn-primary w-full"
    type="submit"
    label="Adicionar"
    [isLoading]="isLoading"
    [disabled]="true"
  ></app-button>
  <app-button
    *ngIf="hasCupon"
    className="btn btn-primary w-full"
    type="button"
    label="Remover"
    [isLoading]="isLoading"
    [disabled]="true"
    (click)="handleRemoveCupon()"
  ></app-button>
</form>
