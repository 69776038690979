<div class="order-overview card" *ngIf="cart">
  <header class="card-header">
      RESUMO DO PEDIDO
    <a *ngIf="!hideChangePlanButton" [routerLink]="'/checkout'"><strong>Trocar Plano</strong></a>
  </header>
  <div class="card-body">
    <span>
      <strong>
        Plano {{ cart.planName }}
      </strong>
      {{ cart.recurrence.value | currency:'R$':true}}
    </span>
    <span class="discount" *ngIf="cart.recurrence.discount && cart.recurrence.discount.value != 0">
      <strong *ngIf="cart.recurrence.discount?.type === 0">
        {{ cart.recurrence.discount?.value}}% de desconto
      </strong>
      <strong *ngIf="cart.recurrence.discount?.type === 1">
        R${{ cart.recurrence.discount?.value}} de desconto
      </strong>

      -{{ cart.recurrence.discount?.value | currency:'R$':true}}
    </span>
    <span>
      <strong>
        Taxa de adesao
      </strong>
      {{ cart.membershipFee.value | currency:'R$':true}}
    </span>
    <span class="discount" *ngIf="cart.membershipFee.discount && cart.membershipFee.discount.value != 0">
      <strong *ngIf="cart.membershipFee.discount?.type === 1">
        {{ cart.membershipFee.discount?.value}}% de desconto
      </strong>
      <strong *ngIf="cart.membershipFee.discount?.type === 0">
        R${{ cart.membershipFee.discount?.value}} de desconto
      </strong>

      -{{ cart.membershipFee.discount?.value | currency:'R$':true}}
    </span>
    <span class="total">
      <strong>
        Total
      </strong>
      {{ cart.totalSale | currency:'R$':true }}
    </span>
    <span class="discount-banner" *ngIf="cart.totalDiscount">
      Você está economizando {{ cart.totalDiscount | currency:'R$':true }}
    </span>
  </div>
</div>
